var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "cart my-lg-5" }, [
    _c("div", { staticClass: "heading" }, [
      _c("h1", [
        _vm._v(_vm._s(_vm.$dictionary("ordering.heading.spares_heading"))),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(_vm._s(_vm.$dictionary("ordering.heading.spares_description"))),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { ref: "addresses", staticClass: "addresses row" }, [
      _c("div", { staticClass: "col-12 col-md-6 col-lg-3" }, [
        _c("address", { staticClass: "box" }, [
          _c("span", { domProps: { innerHTML: _vm._s(_vm.customer.name) } }),
          _vm._v(" "),
          _c("br"),
          _vm._v(
            "\n                " +
              _vm._s(_vm.customer.account_number) +
              "\n            "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-md-6 col-lg-3" }, [
        _c("address", { staticClass: "box" }, [
          _c("strong", [_vm._v(_vm._s(_vm.customer.address.name))]),
          _c("br"),
          _vm._v(" "),
          _c("br"),
          _vm._v(
            "\n                " +
              _vm._s(_vm.customer.address.street) +
              "\n                " +
              _vm._s(_vm.customer.address.postal) +
              " " +
              _vm._s(_vm.customer.address.city) +
              "\n            "
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "row messages" },
      [
        _vm.importBasketMessages.length > 0
          ? [
              _c("div", {
                domProps: { innerHTML: _vm._s(_vm.importBasketMessages) },
              }),
            ]
          : _vm._e(),
        _vm._v(" "),
        _vm._l(_vm.messages, function (message, key) {
          return _c(
            "div",
            { key: "basket-message-" + key, staticClass: "col-12" },
            [
              _c(
                "div",
                { staticClass: "alert", class: "alert-" + message.level },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(message.description) +
                      "\n            "
                  ),
                ]
              ),
            ]
          )
        }),
      ],
      2
    ),
    _vm._v(" "),
    _vm.showCreateOrderError
      ? _c(
          "div",
          { staticClass: "alert alert-danger", attrs: { role: "alert" } },
          [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$dictionary("ordering.basket.create_order_error")) +
                "\n    "
            ),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.loading
      ? _c("div", [
          _c("div", { staticClass: "load-text mb-3" }, [
            _vm._v(
              "\n            " +
                _vm._s(_vm.$dictionary("catalog.preloader_text")) +
                "\n            "
            ),
            _c("span", { staticClass: "point" }, [_vm._v(".")]),
            _vm._v(" "),
            _c("span", { staticClass: "point" }, [_vm._v(".")]),
            _vm._v(" "),
            _c("span", { staticClass: "point" }, [_vm._v(".")]),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    !_vm.loading
      ? _c("div", [
          _vm.hasItems
            ? _c("div", [
                _c(
                  "div",
                  { staticClass: "cart-list spares" },
                  [
                    _c("spares-basket-header"),
                    _vm._v(" "),
                    _vm._l(_vm.itemList, function (item) {
                      return _c(
                        "spares-basket-line",
                        _vm._b(
                          { key: "basket-line-" + item.productInfo.identifier },
                          "spares-basket-line",
                          {
                            basketidentifier: _vm.basketidentifier,
                            item,
                            stock: _vm.getStockForItem(item),
                            prices: _vm.prices,
                            currency: _vm.currency,
                          },
                          false
                        )
                      )
                    }),
                  ],
                  2
                ),
                _vm._v(" "),
                _c("div", { staticClass: "cart-summary row" }, [
                  _c("div", { staticClass: "col-12 col-md-6 mb-4 mb-md-0" }, [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-12" },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-6" }, [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$dictionary("ordering.po_number"))
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-6 po-number" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.poNumber,
                                    expression: "poNumber",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: { type: "text" },
                                domProps: { value: _vm.poNumber },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.poNumber = $event.target.value
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "i",
                                {
                                  staticClass: "icon-question-mark",
                                  class: { hovering: _vm.hoveringPoIcon },
                                  on: {
                                    mouseover: function ($event) {
                                      _vm.hoveringPoIcon = true
                                    },
                                    mouseleave: function ($event) {
                                      _vm.hoveringPoIcon = false
                                    },
                                  },
                                },
                                [_vm._v("?")]
                              ),
                              _vm._v(" "),
                              _vm.hoveringPoIcon
                                ? _c("span", { staticClass: "overlay" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$dictionary(
                                          "ordering.po_number_explanation"
                                        )
                                      )
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "basket-validation-message",
                            _vm._b(
                              {},
                              "basket-validation-message",
                              {
                                key: "po_number_validation_message",
                                showError:
                                  _vm.invalidFields.includes("po_number"),
                                message:
                                  _vm.getValidationMessageForField(
                                    "ordering.po_number"
                                  ),
                              },
                              false
                            )
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-12 mt-3" },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-6" }, [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$dictionary("ordering.partial_delivery")
                                  )
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-6" }, [
                              _c(
                                "div",
                                { staticClass: "form-check form-check-inline" },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.partialDelivery,
                                        expression: "partialDelivery",
                                      },
                                    ],
                                    staticClass: "form-check-input",
                                    attrs: {
                                      type: "radio",
                                      name: "partial_delivery",
                                      id: "partialDeliveryYes",
                                      value: "1",
                                    },
                                    domProps: {
                                      checked: _vm.partialDelivery === "1",
                                      checked: _vm._q(_vm.partialDelivery, "1"),
                                    },
                                    on: {
                                      change: function ($event) {
                                        _vm.partialDelivery = "1"
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "label",
                                    {
                                      staticClass: "form-check-label",
                                      attrs: { for: "partialDeliveryYes" },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                            " +
                                          _vm._s(
                                            _vm.$dictionary(
                                              "ordering.partial_delivery_option_yes"
                                            )
                                          ) +
                                          "\n                                        "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "form-check form-check-inline" },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.partialDelivery,
                                        expression: "partialDelivery",
                                      },
                                    ],
                                    staticClass: "form-check-input",
                                    attrs: {
                                      type: "radio",
                                      name: "partial_delivery",
                                      id: "partialDeliveryNo",
                                      value: "0",
                                    },
                                    domProps: {
                                      checked: _vm.partialDelivery === "0",
                                      checked: _vm._q(_vm.partialDelivery, "0"),
                                    },
                                    on: {
                                      change: function ($event) {
                                        _vm.partialDelivery = "0"
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "label",
                                    {
                                      staticClass: "form-check-label",
                                      attrs: { for: "partialDeliveryNo" },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                        " +
                                          _vm._s(
                                            _vm.$dictionary(
                                              "ordering.partial_delivery_option_no"
                                            )
                                          ) +
                                          "\n                                    "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _vm.partialDelivery === "0"
                            ? _c("p", {
                                staticClass: "mt-3",
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.$dictionary(
                                      "ordering.full_delivery_explanation"
                                    )
                                  ),
                                },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "basket-validation-message",
                            _vm._b(
                              {},
                              "basket-validation-message",
                              {
                                key: "partial_delivery",
                                showError:
                                  _vm.invalidFields.includes(
                                    "partial_delivery"
                                  ),
                                message: _vm.getValidationMessageForField(
                                  "ordering.partial_delivery"
                                ),
                              },
                              false
                            )
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-lg-3 col-md-6 offset-lg-3" },
                    [
                      _c(
                        "basket-summary",
                        _vm._b(
                          {},
                          "basket-summary",
                          {
                            subtotal: _vm.subtotal,
                            shippingCosts: _vm.shipping,
                            uplift: _vm.uplift,
                            totalVat: _vm.taxes,
                            grandTotal: _vm.grandTotal,
                            currency: _vm.currency,
                          },
                          false
                        )
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "addresses" },
                  [
                    _c("h3", [
                      _vm._v(
                        _vm._s(_vm.$dictionary("ordering.ship_to.heading"))
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "basket-validation-message",
                      _vm._b(
                        {},
                        "basket-validation-message",
                        {
                          key: "ship_to",
                          showError: _vm.invalidFields.includes("ship_to"),
                          message: _vm.getValidationMessageForField(
                            "ordering.ship_to.heading"
                          ),
                        },
                        false
                      )
                    ),
                    _vm._v(" "),
                    _c(
                      "ship-to-address-selector",
                      _vm._b(
                        {
                          model: {
                            value: _vm.shipTo,
                            callback: function ($$v) {
                              _vm.shipTo = $$v
                            },
                            expression: "shipTo",
                          },
                        },
                        "ship-to-address-selector",
                        {
                          addresses: _vm.customer.ship_to_addresses,
                          selectedAddressId: _vm.shipTo,
                          countries: _vm.countries,
                        },
                        false
                      )
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.hasItems
            ? _c("p", { staticClass: "alert alert-info mb-5" }, [
                _vm._v(_vm._s(_vm.$dictionary("ordering.basket.basket_empty"))),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", [
      !_vm.readonly
        ? _c("div", { domProps: { innerHTML: _vm._s(_vm.cancelbutton) } })
        : _vm._e(),
      _vm._v(" "),
      _c("div", [
        _c(
          "button",
          {
            staticClass: "btn btn-blue-outline ripple btn-sm-block ml-3",
            class: { disabled: _vm.checkoutButtonDisabled },
            attrs: { disabled: _vm.checkoutButtonDisabled },
            on: {
              click: function ($event) {
                return _vm.emptyBasket()
              },
            },
          },
          [
            _vm._v(
              _vm._s(_vm.$dictionary("ordering.basket.empty_basket")) +
                "\n            "
            ),
          ]
        ),
        _vm._v(" "),
        !_vm.readonly
          ? _c(
              "button",
              {
                staticClass: "btn btn-primary ripple float-right btn-sm-block",
                class: { disabled: _vm.checkoutButtonDisabled },
                attrs: { disabled: _vm.checkoutButtonDisabled },
                on: {
                  click: function ($event) {
                    return _vm.checkout()
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$dictionary("ordering.basket.checkout")))]
            )
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }