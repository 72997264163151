<template>
    <div v-if="showError" class="row">
        <div class="col-12 mt-2">
            <div class="alert alert-danger">{{ message }}</div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        message: String,
        showError: Boolean
    }
}
</script>
