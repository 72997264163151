var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container p-0 open-orders-widget" }, [
    _c("h1", [_vm._v(_vm._s(_vm.dictionary("reports.turnover_report")))]),
    _vm._v(" "),
    _c("div", { staticClass: "row open-order-overview my-3" }, [
      _c("div", { staticClass: "col-md-6 mb-3" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.materialNumber,
              expression: "materialNumber",
            },
          ],
          staticClass: "form-control",
          attrs: {
            type: "text",
            placeholder: _vm.$dictionary("reports.material_number"),
          },
          domProps: { value: _vm.materialNumber },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.materialNumber = $event.target.value
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-6 mb-3" }, [
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.customer,
                expression: "customer",
              },
            ],
            staticClass: "form-control",
            on: {
              change: function ($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function (o) {
                    return o.selected
                  })
                  .map(function (o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.customer = $event.target.multiple
                  ? $$selectedVal
                  : $$selectedVal[0]
              },
            },
          },
          [
            _c("option", { attrs: { value: "" } }, [
              _vm._v(_vm._s(_vm.$dictionary("reports.customer.empty"))),
            ]),
            _vm._v(" "),
            _vm._l(_vm.customers, function (customer) {
              return _c(
                "option",
                { domProps: { value: customer.account_number } },
                [
                  _vm._v(_vm._s(customer.account_number) + " | "),
                  _c("span", {
                    domProps: { innerHTML: _vm._s(customer.name) },
                  }),
                ]
              )
            }),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-6 mb-3" }, [
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.brand,
                expression: "brand",
              },
            ],
            staticClass: "form-control",
            on: {
              change: function ($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function (o) {
                    return o.selected
                  })
                  .map(function (o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.brand = $event.target.multiple
                  ? $$selectedVal
                  : $$selectedVal[0]
              },
            },
          },
          [
            _c("option", { attrs: { value: "" } }, [
              _vm._v(_vm._s(_vm.$dictionary("reports.brand.empty"))),
            ]),
            _vm._v(" "),
            _vm._l(_vm.brands, function (brand, id) {
              return _c("option", { domProps: { value: id } }, [
                _vm._v(_vm._s(brand)),
              ])
            }),
          ],
          2
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("h1", [_vm._v(_vm._s(_vm.dictionary("reports.invoice_date")))]),
    _vm._v(" "),
    _c("div", { staticClass: "row open-order-delivery-date my-3" }, [
      _c(
        "div",
        { staticClass: "col-md-6 mb-3" },
        [
          _c("flat-pickr", {
            staticClass: "form-control input_date",
            attrs: {
              config: _vm.config,
              placeholder: _vm.$dictionary("reports.basket.date_from"),
              name: "date_from",
            },
            model: {
              value: _vm.date_from,
              callback: function ($$v) {
                _vm.date_from = $$v
              },
              expression: "date_from",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-md-6 mb-3" },
        [
          _c("flat-pickr", {
            staticClass: "form-control input_date",
            attrs: {
              config: _vm.config,
              placeholder: _vm.$dictionary("reports.basket.date_to"),
              name: "date_to",
            },
            model: {
              value: _vm.date_to,
              callback: function ($$v) {
                _vm.date_to = $$v
              },
              expression: "date_to",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-6 mb-3" }, [
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.currency,
                expression: "currency",
              },
            ],
            staticClass: "form-control",
            on: {
              change: function ($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function (o) {
                    return o.selected
                  })
                  .map(function (o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.currency = $event.target.multiple
                  ? $$selectedVal
                  : $$selectedVal[0]
              },
            },
          },
          [
            _c("option", { attrs: { value: "" } }, [
              _vm._v(_vm._s(_vm.dictionary("reports.select_currency"))),
            ]),
            _vm._v(" "),
            _vm._l(_vm.currencies, function (option) {
              return _c("option", { domProps: { value: option.code } }, [
                _vm._v(_vm._s(option.code)),
              ])
            }),
          ],
          2
        ),
      ]),
    ]),
    _vm._v(" "),
    _vm.searching
      ? _c("div", { attrs: { id: "loading_indicator" } }, [
          _c("div", { staticClass: "centered" }, [
            _c(
              "svg",
              {
                staticStyle: { "enable-background": "new 0 0 75.6 75.6" },
                attrs: {
                  version: "1.1",
                  id: "loading-circle",
                  xmlns: "http://www.w3.org/2000/svg",
                  "xmlns:xlink": "http://www.w3.org/1999/xlink",
                  x: "0px",
                  y: "0px",
                  viewBox: "0 0 75.6 75.6",
                  "xml:space": "preserve",
                },
              },
              [
                _c("g", [
                  _c("g", [
                    _c("path", {
                      staticClass: "ring ring1",
                      attrs: {
                        d: "M20.1,70.4C8,64,0.4,51.5,0.4,37.8C0.4,17.5,16.9,1,37.2,1c11.5,0,22.1,5.2,29.2,14.4l-3.7,2.9\n                    c-6.1-8-15.4-12.5-25.4-12.5C19.5,5.7,5.1,20.1,5.1,37.8c0,12,6.6,22.9,17.2,28.4C22.3,66.2,20.1,70.4,20.1,70.4z",
                      },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      staticClass: "ring ring3",
                      attrs: {
                        d: "M37.2,25.3c-6.9,0-12.5,5.6-12.5,12.5c0,6.9,5.6,12.5,12.5,12.5c6.9,0,12.5-5.6,12.5-12.5\n                    C49.7,30.9,44.1,25.3,37.2,25.3 M37.2,61.6c-13.1,0-23.8-10.7-23.8-23.8C13.4,24.6,24,14,37.2,14C50.3,14,61,24.6,61,37.8\n                    C61,50.9,50.3,61.6,37.2,61.6",
                      },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      staticClass: "ring ring2",
                      attrs: {
                        d: "M37.2,67.4c-0.3,0-0.7,0-1,0c-10.4-0.3-19.6-5.9-24.8-15l2.7-1.6C18.8,59,27,64,36.3,64.3\n                    c14.6,0.5,26.9-11,27.4-25.6c0.4-11.5-6.7-21.9-17.5-25.8l1.1-3c12.1,4.4,20,16,19.6,28.9C66.3,54.8,53.1,67.4,37.2,67.4",
                      },
                    }),
                  ]),
                ]),
              ]
            ),
            _vm._v(" "),
            _c("span", { staticClass: "load-text mt-3" }, [
              _vm._v(_vm._s(_vm.dictionary("catalog.preloader_text"))),
            ]),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "a",
      {
        staticClass: "btn btn-primary mb-5",
        attrs: { href: "#" },
        on: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.getReports.apply(null, arguments)
          },
        },
      },
      [_vm._v(_vm._s(_vm.dictionary("reports.show_report")))]
    ),
    _vm._v(" "),
    _vm.emptyResponse && this.error === null
      ? _c("h1", {
          domProps: {
            innerHTML: _vm._s(
              _vm.dictionary("login.customer_select.no_results_found")
            ),
          },
        })
      : _c("h1", { domProps: { innerHTML: _vm._s(this.error) } }),
    _vm._v(" "),
    _vm.hasSearched && !_vm.emptyResponse
      ? _c("div", [
          _c("h1", [_vm._v(_vm._s(_vm.dictionary("reports.summary")))]),
          _vm._v(" "),
          _c("div", { staticClass: "row open-order-summary my-3" }, [
            _c("div", { staticClass: "col-md-12" }, [
              _c("table", { staticClass: "table" }, [
                _c("tbody", [
                  _c("tr", [
                    _c("td", [
                      _vm._v(
                        _vm._s(_vm.dictionary("reports.number_of_invoices"))
                      ),
                    ]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(this.data.totalItems))]),
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("td", [
                      _vm._v(_vm._s(_vm.dictionary("reports.dates_from_to"))),
                    ]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(this.data.search_period))]),
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("td", [
                      _vm._v(_vm._s(_vm.dictionary("reports.material_number"))),
                    ]),
                    _vm._v(" "),
                    !this.materialNumber
                      ? _c("td", [_vm._v("-")])
                      : _c("td", [_vm._v(_vm._s(this.materialNumber))]),
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("td", [
                      _vm._v(_vm._s(_vm.dictionary("reports.customer"))),
                    ]),
                    _vm._v(" "),
                    !this.customer
                      ? _c("td", [
                          _vm._v(
                            _vm._s(_vm.dictionary("reports.customer.empty"))
                          ),
                        ])
                      : _c("td", [_vm._v(_vm._s(this.customer))]),
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("td", [
                      _vm._v(_vm._s(_vm.dictionary("reports.total_quantity"))),
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        _vm._s(
                          this.data.total_turnover_result.invoiced_quantity
                        )
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("td", [
                      _vm._v(_vm._s(_vm.dictionary("reports.total_value"))),
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        "\n                            " +
                          _vm._s(
                            _vm.formatPrice(
                              this.data.total_turnover_result.gross_value,
                              this.data.total_turnover_result.currency
                            )
                          ) +
                          "\n                        "
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "add-to-basket d-flex align-items-center justify-content-center",
            },
            [
              _c(
                "a",
                {
                  staticClass: "btn btn-secondary mr-4",
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.showDetails.apply(null, arguments)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.dictionary("reports.show_details")))]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "btn btn-secondary",
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.getExelFile.apply(null, arguments)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.dictionary("reports.export_to_excel")))]
              ),
            ]
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "modal",
        attrs: { tabindex: "-1", role: "dialog", id: "open-orders-modal" },
      },
      [
        _c(
          "div",
          {
            staticClass: "modal-dialog modal-full-width",
            attrs: { role: "document" },
          },
          [
            _c("div", { staticClass: "modal-content" }, [
              _c("div", { staticClass: "modal-body" }, [
                _vm._m(0),
                _vm._v(" "),
                _c("table", { staticClass: "table striped" }, [
                  _c("thead", [
                    _c("tr", [
                      _c("th", [
                        _vm._v(
                          _vm._s(_vm.dictionary("reports.modal.currency"))
                        ),
                      ]),
                      _vm._v(" "),
                      _c("th", [
                        _vm._v(
                          _vm._s(
                            _vm.dictionary("reports.modal.customer_number")
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c("th", [
                        _vm._v(
                          _vm._s(_vm.dictionary("reports.modal.customer"))
                        ),
                      ]),
                      _vm._v(" "),
                      _c("th", [
                        _vm._v(
                          _vm._s(
                            _vm.dictionary("reports.modal.customer_address")
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c("th", [
                        _vm._v(
                          _vm._s(
                            _vm.dictionary("reports.modal.material_number")
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c("th", [
                        _vm._v(_vm._s(_vm.dictionary("reports.modal.period"))),
                      ]),
                      _vm._v(" "),
                      _c("th", [
                        _vm._v(
                          _vm._s(_vm.dictionary("reports.modal.description"))
                        ),
                      ]),
                      _vm._v(" "),
                      _c("th", [
                        _vm._v(
                          _vm._s(_vm.dictionary("reports.modal.gross_value"))
                        ),
                      ]),
                      _vm._v(" "),
                      _c("th", [
                        _vm._v(
                          _vm._s(_vm.dictionary("reports.modal.quantity"))
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    [
                      _vm._l(this.data.items, function (customerGroup) {
                        return _vm._l(customerGroup, function (item) {
                          return _c("tr", [
                            _c("td", [_vm._v(_vm._s(item.currency))]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(item.customerNumber))]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(item.customerName))]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(item.customerAddress))]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(item.materialNumber))]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(item.from))]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(item.description))]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.formatPrice(
                                    item.gross_value,
                                    item.currency
                                  )
                                ) + "\n                                "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(item.invoiced_quantity))]),
                          ])
                        })
                      }),
                    ],
                    2
                  ),
                ]),
              ]),
            ]),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-close" }, [
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close",
          },
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }