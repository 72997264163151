<template>
    <div class="card product-card" :id="'product' + product.id">
        <a :href="detailUrl" class="card-img-holder text-center">
            <img :src="image" class="card-img-top img-fluid" :alt="productName">
        </a>

        <div class="card-body d-flex flex-column">
            <h4 class="card-title">
                <a :title="product.info.artno" :href="detailUrl" v-html="product.info.artno"></a>
            </h4>

            <span class="item-title">
                <a :title="productName" :href="detailUrl" v-html="productName"></a>
            </span>

            <div class="price" :class="{
                'loading': loading,
                'no-price': noPrice
                }">


                
                <div v-if="showRecommendedRetailPrice">
                    <span class="SalePrice">
                        {{ rrpPrice }} <span class="price-vat">{{ $dictionary('ordering.price.inc.rrp') }}</span>
                    </span>
                </div>
                <div v-else>
                    <span class="SalePrice" v-if="grossPrice !== null">
                        {{ grossPrice }} <span class="price-vat">{{ $dictionary('ordering.price.vat') }}</span>
                    </span>
                </div>

                
                <span class="SalePrice greyedOut" v-if="noPrice">
                    <span class="price-vat">{{ $dictionary('ordering.price.vat') }}</span>
                </span>
            </div>

            <add-to-basket
                v-bind="{
                    item: product.id,
                    basketidentifier,
                    disabled: ! orderable,
                    itemOrderable: orderable,
                    orderLimit: product.info.order_limit,
                    noPrice: this.noPrice,
                    minimalOrderQuantity: this.minimalOrderQuantity,
                    indivisibleUnit: this.indivisibleUnit
                }"
                ></add-to-basket>

            <item-stock-sales
                v-if="!hideStock"
                v-bind="{
                    artno: product.info.artno
                }"
            ></item-stock-sales>
        </div>
    </div>
</template>

<script>
    import currency from '../../js/helpers/currency'
    import {mapGetters} from 'vuex'

    export default {
        props: {
            productJson: String,
            productName: String,
            detailUrl: String,
            image: String,
            basketidentifier: String,
            hideStock: Boolean,
            currency: String,
            minimalOrderQuantity: Number,
            indivisibleUnit: Number,
        },
        data() {
            return {
                loading : true,
                noPrice: false
            }
        },
        computed: {
            ...mapGetters('lister', [
                'prices'
            ]),
            product() {
                return JSON.parse(this.productJson)
            },
            price() {
                for (const priceObj of this.prices) {
                    if (priceObj.identifier === this.product.info.artno) {
                        return priceObj
                    }
                }

                return null
            },
            grossPrice() {
                if (this.price == null) {
                    return null
                }

                if (! this.price.hasOwnProperty('price_inc')) {
                    return null
                }

                if (this.price.price_inc === null) {
                    this.noPrice = true
                }

                return currency.formatPrice(this.price.price_inc, this.price.currency)
            },
            rrpPrice() {
                if (this.price == null) {
                    return null
                }
                return currency.formatPrice(this.price.recommended_retail_price, this.price.currency)
            },
            showRecommendedRetailPrice() {
                return store.state.showRecommendedRetailPrice;
            },
            orderable() {
                return this.grossPrice !== null
            }
        },
        watch: {
            grossPrice() {
                this.loading = false;
            }
        }
    }
</script>
