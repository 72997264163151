var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container p-0 manage-accounts-widget" },
    [
      _vm._l(_vm.members, function (member) {
        return _c("manage-customer-account-row", {
          key: "member_" + member.id,
          attrs: { member: member },
          on: {
            edit: _vm.handleEdit,
            delete: _vm.handleDelete,
            disable: _vm.handleDisable,
            enable: _vm.handleEnable,
          },
        })
      }),
      _vm._v(" "),
      _vm.members.length === 0
        ? _c("div", { staticClass: "row no-gutters mb-5" }, [
            _c("td", { staticClass: "bold", attrs: { colspan: "5" } }, [
              _vm._v(
                _vm._s(
                  _vm.dictionary("manage-accounts.no-accounts-found-message")
                )
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("a", { staticClass: "btn btn-primary", attrs: { href: _vm.newUrl } }, [
        _vm._v(_vm._s(_vm.dictionary("manage-accounts.new-account"))),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }