var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "swiper-wrapper" },
    _vm._l(_vm.products, function (product, key) {
      return _c(
        "div",
        {
          key: key,
          staticClass: "swiper-slide",
          attrs: { "data-product-id": product.id },
        },
        [
          _c("product-tile", {
            staticClass: "vue-el",
            attrs: {
              id: "'product-tile-product-'",
              "product-json": product.json,
              "product-name": product.info.name.en,
              "detail-url": product.url,
              image: product.scaledImage,
              basketidentifier: _vm.basketidentifier,
              "hide-stock": _vm.hideStock,
            },
          }),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }