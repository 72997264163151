import helpers from '@core/js/helpers';

export default {

    formatPrice(price, currencyCode)
    {
        if (
            price === undefined ||
            currencyCode === undefined
        ) {
            return price;
        }

        const currency = this.getCurrency(currencyCode);

        if (currency === undefined) {
            return price;
        }

        return currency.symbol + ' ' + helpers.price.formatPrice(
            price,
            currency.decimals,
            currency.decimalSymbol,
            currency.thousandsSymbol
        )
    },

    getCurrency(currency)
    {
        return currencies[currency]
    }
}
