// mobile filters
var listerFilters = {
    init: function() {

    $('.js-toggle-filters').click(function () {
        $('.left').toggleClass('active');
    });

    $('.js-toggle-clear').click(function () {
        $('.left').toggleClass('active');
    });
        
    }
}

$(document).ready(function () {
    listerFilters.init();
});