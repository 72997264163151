var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "col-12 customer-selection-widget" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "heading col-12" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            {
              staticClass:
                "col-sm-12 col-lg-9 d-flex align-items-center mb-3 mb-lg-0",
            },
            [
              _c(
                "ul",
                {
                  staticClass:
                    "letter-select d-inline-flex flex-wrap list-unstyled mb-0",
                },
                _vm._l(_vm.letters, function (letter, key) {
                  return _c(
                    "li",
                    {
                      key: "letter-" + key,
                      staticClass: "letter",
                      class: { disabled: _vm.isDisabled(letter.toString()) },
                    },
                    [
                      _vm.isDisabled(letter.toString())
                        ? [
                            _vm._v(
                              "\n                                " +
                                _vm._s(letter) +
                                "\n                            "
                            ),
                          ]
                        : _c(
                            "a",
                            {
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  _vm.enableLetterSearch(letter.toString())
                                },
                              },
                            },
                            [_vm._v(_vm._s(letter))]
                          ),
                    ],
                    2
                  )
                }),
                0
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "red-link reset-letters d-inline-flex ml-1 mb-2",
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.disableLetterSearch.apply(null, arguments)
                    },
                  },
                },
                [_c("i", { staticClass: "icon icon-redo-alt-solid" })]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "search-box col-sm-12 col-lg-3 d-flex align-items-center mb-2 mb-lg-0",
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchWord,
                    expression: "searchWord",
                  },
                ],
                attrs: {
                  type: "text",
                  placeholder: _vm.dictionary(
                    "login.customer_select.search_button_placeholder"
                  ),
                },
                domProps: { value: _vm.searchWord },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.searchWord = $event.target.value
                  },
                },
              }),
            ]
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "customer-selection col-12" },
        [
          _vm._l(_vm.filteredCustomers, function (customer) {
            return _c("customer-selection-item", {
              key: customer.id,
              attrs: {
                customer: customer,
                active: _vm.selectedCustomer
                  ? _vm.selectedCustomer.id === customer.id
                  : false,
              },
              model: {
                value: _vm.selectedCustomer,
                callback: function ($$v) {
                  _vm.selectedCustomer = $$v
                },
                expression: "selectedCustomer",
              },
            })
          }),
          _vm._v(" "),
          _vm.filteredCustomers.length === 0
            ? _c("div", {
                staticClass: "no-results-found mb-4",
                domProps: {
                  innerHTML: _vm._s(
                    _vm.dictionary("login.customer_select.no_results_found")
                  ),
                },
              })
            : _vm._e(),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }