import BasketLine from './SoundUnitedBasketLine.js';
import BaseBasket from '@core/js/includes/Basket'

class Basket  extends BaseBasket {
    constructor(basketIdentifier)
    {
        super(basketIdentifier);
    }

    createLine(lineData)
    {
        return new BasketLine(
            lineData.identifier,
            lineData.productInfo,
            lineData.quantity,
            lineData.unitPrice,
            lineData.totalPrice,
            lineData.promotion,
            lineData.messages,
            lineData.metadata,
            [],
            lineData.remark,
            lineData.deliveryDate,
        );
    }

    processBasketResult(result)
    {
        this.updateLines(result.lines);
        this.updateCombiLines(result.combinationLines);
        this.updateSubtotalLines(result.subtotalLines);
        this.updateTotalLines(result.totalLines);
        this.updateMessages(result.messages);
        this.updateSubtotalPrice(result.subtotalPrice);
        this.updateTotalPrice(result.totalPrice);
        if (result.totalPrice !== undefined) {
            this.updateTaxes(result.totalPrice.vat);
        } else {
            this.updateTaxes();
        }
        this.updateGrandTotal(result.grandTotal);
        this.partial_delivery = result.partial_delivery
        this.po_number = result.po_number
        this.shipping_address = result.shipping_address

        this.fireChangedEvent();
    }
}

export default Basket;
