var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "price d-flex",
      class: {
        loading: _vm.loading,
        "no-price": _vm.noPrice,
      },
    },
    [
      _vm.noPrice
        ? _c("h5", { staticClass: "greyedOut" }, [
            _c("span", { staticClass: "price-vat" }, [
              _vm._v(_vm._s(_vm.$dictionary("ordering.price.vat"))),
            ]),
          ])
        : _c("h5", [
            _vm.showRecommendedRetailPrice
              ? _c("div", [
                  _c("span", [_vm._v(_vm._s(_vm.recommendedRetailPrice))]),
                  _vm._v(" "),
                  _c("span", { staticClass: "price-vat" }, [
                    _vm._v(_vm._s(_vm.$dictionary("ordering.price.inc.rrp"))),
                  ]),
                ])
              : _c("div", [
                  _c("span", [_vm._v(_vm._s(_vm.grossPrice))]),
                  _vm._v(" "),
                  _c("span", { staticClass: "price-vat" }, [
                    _vm._v(_vm._s(_vm.$dictionary("ordering.price.vat"))),
                  ]),
                ]),
          ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }