var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "add-to-basket",
        _vm._b(
          {},
          "add-to-basket",
          {
            item: _vm.productId,
            basketidentifier: _vm.basketidentifier,
            disabled: !_vm.orderable,
            itemOrderable: _vm.orderable,
            orderLimit: _vm.orderLimit,
            noPrice: this.noPrice,
            minimalOrderQuantity: _vm.minimalQuantity,
            indivisibleUnit: _vm.indivisible,
          },
          false
        )
      ),
      _vm._v(" "),
      !_vm.hideStock
        ? _c("item-stock-sales", { attrs: { artno: _vm.artno } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }