var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "btn-group minibasket-btn-group" }, [
    _vm.showRecommendedRetailPrice
      ? _c(
          "a",
          {
            staticClass: "btn btn-text dropdown-toggle btn-mini-basket",
            attrs: { href: _vm.url, type: "button" },
          },
          [
            _c("i", { staticClass: "icon icon-cart" }),
            _vm._v(" "),
            _vm.size
              ? _c(
                  "span",
                  {
                    staticClass: "basket-counter",
                    class: { "animate puffIn": _vm.size },
                  },
                  [_vm._v(_vm._s(_vm.size))]
                )
              : _vm._e(),
          ]
        )
      : _c(
          "button",
          {
            staticClass: "btn btn-text dropdown-toggle btn-mini-basket",
            attrs: {
              type: "button",
              "data-toggle": "dropdown",
              "aria-haspopup": "true",
              "aria-expanded": "false",
            },
          },
          [
            _c("i", { staticClass: "icon icon-cart" }),
            _vm._v(" "),
            _vm.size
              ? _c(
                  "span",
                  {
                    staticClass: "basket-counter",
                    class: { "animate puffIn": _vm.size },
                  },
                  [_vm._v(_vm._s(_vm.size))]
                )
              : _vm._e(),
          ]
        ),
    _vm._v(" "),
    _c("div", { staticClass: "dropdown-menu dropdown-menu-right" }, [
      _c("h3", { staticClass: "bold text-uppercase h6" }, [
        _vm._v(_vm._s(_vm.$dictionary("ordering.basket.minibaskettitle"))),
      ]),
      _vm._v(" "),
      _vm.hasItems
        ? _c("div", { staticClass: "mini-basket-items" }, [
            _c(
              "ul",
              { staticClass: "list-group list-group-flush" },
              _vm._l(_vm.itemList, function (item) {
                return _c(
                  "li",
                  {
                    key: item.productInfo.identifier,
                    staticClass: "list-group-item px-0",
                  },
                  [
                    _c("div", { staticClass: "item-quantity" }, [
                      _vm._v(_vm._s(item.getQuantity()) + "×"),
                    ]),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "item-name",
                      domProps: { innerHTML: _vm._s(item.productInfo.name) },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "item-price pr-2" },
                      [
                        _vm.hasPrices
                          ? [
                              _c(
                                "span",
                                {
                                  staticClass: "price d-block text-right bold",
                                },
                                [
                                  _vm._v(
                                    "\n                               " +
                                      _vm._s(_vm.getPrice(item)) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                            ]
                          : [
                              _c("div", { staticClass: "loader" }, [
                                _vm._v("Loading..."),
                              ]),
                            ],
                      ],
                      2
                    ),
                  ]
                )
              }),
              0
            ),
            _vm._v(" "),
            _c("div", { staticClass: "total mt-3 clearfix" }, [
              _c("span", { staticClass: "float-left bold" }, [
                _vm._v(_vm._s(_vm.$dictionary("ordering.basket.subtotal"))),
              ]),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "float-right bold pr-2" },
                [
                  _vm.subtotal
                    ? [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.formatPrice(_vm.subtotal)) +
                            "\n                    "
                        ),
                      ]
                    : [
                        _c("div", { staticClass: "loader" }, [
                          _vm._v("Loading..."),
                        ]),
                      ],
                ],
                2
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "total mt-3 clearfix" }, [
              _c("span", { staticClass: "float-left bold" }, [
                _vm._v(_vm._s(_vm.$dictionary("ordering.basket.tax"))),
              ]),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "float-right bold pr-2" },
                [
                  _vm.taxes !== null
                    ? [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.formatPrice(_vm.taxes)) +
                            "\n                    "
                        ),
                      ]
                    : [
                        _c("div", { staticClass: "loader" }, [
                          _vm._v("Loading..."),
                        ]),
                      ],
                ],
                2
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "total mt-3 clearfix" }, [
              _c("span", { staticClass: "float-left bold" }, [
                _vm._v(_vm._s(_vm.$dictionary("ordering.basket.grandtotal"))),
              ]),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "float-right bold pr-2" },
                [
                  _vm.grandTotal
                    ? [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.formatPrice(_vm.grandTotal)) +
                            "\n                    "
                        ),
                      ]
                    : [
                        _c("div", { staticClass: "loader" }, [
                          _vm._v("Loading..."),
                        ]),
                      ],
                ],
                2
              ),
            ]),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "btn btn-primary ripple btn-block mt-2",
                attrs: { href: _vm.url },
              },
              [_vm._v(_vm._s(_vm.$dictionary("ordering.basket.viewcart")))]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.hasItems
        ? _c("p", { staticClass: "alert alert-info" }, [
            _vm._v(_vm._s(_vm.$dictionary("ordering.basket.basket_empty"))),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }