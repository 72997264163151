<template>
    <div class="row result no-gutters" :class="{ alternative : isAlternative }">
        <div class="itemnr col-6 col-md-4 col-lg-2">
            {{ item.info.artno }}
        </div>
        <div class="description col-6 col-md-4 col-lg-3">
            {{ name }}
        </div>
        <div class="quantity text-center col-12 col-md-4 col-lg-2">
                <add-to-basket
                    v-bind="{
                        item: item.id,
                        basketidentifier,
                        disabled: ! orderable,
                        itemOrderable: orderable,
                        orderLimit,
                        checkOrderLimit: true,
                        nlaStatus,
                        minimalOrderQuantity: minimalQuantity,
                        indivisibleUnit: indivisible
                    }"
                    small="1"
                ></add-to-basket>
        </div>
        <div class="col-12 col-lg-5 mt-md-3 mt-md-0 mt-3 mb-2 mb-md-0 mt-lg-0" :class="{ alternative : isAlternative }">
            <div class="row">
                <item-stock v-bind="{
                    stock: stockObj ? stockObj.stock : null,
                    quantity: stockObj ? stockObj.quantity : null,
                    nlaStatus,
                    estimatedTimeArrival,
                    basketAmount
                }"></item-stock>

                <template v-if="nlaStatus">
                    <div class="netprice col-4 col-md-2" :class="{ greyedOut: nlaStatus }">
                        -
                    </div>
                    <div class="grossprice col-5 col-md-2" :class="{ greyedOut: nlaStatus }">
                        -
                    </div>
                </template>

                <template v-else>
                    <div class="netprice col-4 col-md-2">
                        <template v-if="netPrice !== null">
                            {{ formatPrice(netPrice) }}
                        </template>
                        <template v-else>
                            <div class="loader">Loading...</div>
                        </template>
                    </div>
                    <div class="grossprice col-5 col-md-2">
                        <template v-if="grossPrice !== null">
                            {{ formatPrice(grossPrice) }}
                        </template>
                        <template v-else>
                            <div class="loader">Loading...</div>
                        </template>
                    </div>
                </template>
                <div class="orderable text-left text-md-center col-2 col-md-2">
                    <i class="icon" :class="{'icon-check-solid c-info-green': orderable, 'icon-times-solid c-info-red': !orderable}"></i>
                </div>
                <div class="eta text-left text-md-center col-4 offset-1 offset-md-0 col-md-1 my-2 my-md-0">
                    {{ estimatedTimeArrival }}
                </div>
                <div class="nla text-left text-md-center col-5 col-md-2 my-2 my-md-0">
                    {{ nlaStatus }}
                </div>
                <div class="orderlimit text-center col-2 col-md-2 my-2 my-md-0">
                    {{ orderLimit }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import currency from '../../js/helpers/currency'

    export default {
        props: {
            item: Object,
            language: String,
            basketidentifier: String,
            prices: Array,
            stock: [Array, Object],
            organisationId: String,
            isAlternative: Boolean
        },
        data() {
            return {
                basketAmount: 0
            }
        },
        computed: {
            name() {
                if (this.item.info.name[this.language]) {
                    return this.item.info.name[this.language]
                }

                return this.item.info.name.en
            },
            nlaStatus() {
                for (let organisation of this.item.organisations) {
                    if (organisation.organisation_id !== parseInt(this.organisationId)) {
                        continue
                    }

                    if (organisation.dchain_valid_date === null) {
                        continue
                    }

                    if (organisation.dchain_spec_status !== 'NL') {
                        continue
                    }

                    const currentDate = new Date()
                    const expireDate = new Date(organisation.dchain_valid_date)

                    if (currentDate >= expireDate) {
                        return 'NLA'
                    }
                }

                return ''
            },
            price() {
                for (const priceObj of this.prices) {
                    if (priceObj.identifier === this.item.info.artno) {
                        return priceObj
                    }
                }

                return null
            },
            netPrice() {
                if (this.price == null) {
                    return null
                }

                if (! this.price.hasOwnProperty('price_inc')) {
                    return null
                }

                return this.price.price_inc
            },
            grossPrice() {
                if (this.price == null) {
                    return null
                }

                if (! this.price.hasOwnProperty('gross_price')) {
                    return null
                }

                return this.price.gross_price
            },
            orderable() {
                if (this.netPrice === null) {
                    return false
                }

                if (this.stockObj === null) {
                    return false
                }

                if (this.nlaStatus !== '') {
                    return false
                }

                return true;
            },
            stockObj() {
                if (this.stock.hasOwnProperty(this.item.info.artno)) {
                    return this.stock[this.item.info.artno]
                }

                return null
            },
            estimatedTimeArrival() {
                if (this.item.info.week_number > 0) {
                    return this.item.info.week_number
                }
                return ''
            },
            orderLimit() {
                if (this.item.info.order_limit > 0) {
                    return this.item.info.order_limit
                }
                return null
            },
            minimalQuantity() {
                for (let organisation of this.item.organisations) {
                    if (organisation.organisation_id !== parseInt(this.organisationId)) {
                        continue
                    }
                    return organisation.min_order_quantity
                }
            },
            indivisible() {
                for (let organisation of this.item.organisations) {
                    if (organisation.organisation_id !== parseInt(this.organisationId)) {
                        continue
                    }
                    return organisation.min_delivery_quantity
                }
            }
        },
        methods: {
            formatPrice: function(price) {
                return currency.formatPrice(price, this.price.currency)
            }
        },
        created() {
            var basket = window.BasketManager.get(this.basketidentifier)
            this.basketAmount = basket.getQuantityForItem(this.item.id)

            window.serverBus.$on('basket.changed', (basket) => {
                this.basketAmount = basket.getQuantityForItem(this.item.id);
            })
        }
    }
</script>
