var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row result no-gutters" }, [
    _c("div", { staticClass: "description col-lg-4" }, [
      _c("h4", { staticClass: "m-0" }, [
        _vm._v(_vm._s(_vm.item.productInfo.artno)),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "m-0" }, [
        _vm._v(_vm._s(_vm.item.productInfo.name)),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "quantity text-center col-lg-3" },
      [
        _c(
          "add-to-basket",
          _vm._b(
            { attrs: { small: "1" } },
            "add-to-basket",
            {
              item: _vm.item.productInfo.identifier,
              basketidentifier: _vm.basketidentifier,
              orderLimit: _vm.item.productInfo.order_limit,
              disabled: false,
              itemOrderable: _vm.orderable,
              checkOrderLimit: true,
              removePreLoader: true,
              minimalOrderQuantity: _vm.item.productInfo.minimal_order_quantity,
              indivisibleUnit: _vm.item.productInfo.indivisible_unit,
            },
            false
          )
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "grossprice col-lg-2" }, [
      _vm.showRecommendedRetailPrice
        ? _c("div", [
            _vm._v(
              "\n            " +
                _vm._s(_vm.formatPrice(_vm.rrpPrice)) +
                "\n        "
            ),
          ])
        : _c(
            "div",
            [
              _vm.grossPrice !== null
                ? [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.formatPrice(_vm.grossPrice)) +
                        "\n            "
                    ),
                  ]
                : [
                    _c("div", { staticClass: "loader" }, [
                      _vm._v("Loading..."),
                    ]),
                  ],
            ],
            2
          ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "orderable text-left text-md-center col-lg-2" }, [
      _c("i", {
        staticClass: "icon",
        class: {
          "icon-check-solid c-info-green": _vm.orderable,
          "icon-times-solid c-info-red": !_vm.orderable,
        },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }