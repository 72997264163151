<template>
    <div class="swiper-wrapper">
        <div v-for="(product,key) in products" class="swiper-slide" :data-product-id="product.id" :key="key">
            <product-tile
                    id="'product-tile-product-'"
                    class="vue-el"
                    :product-json='product.json'
                    :product-name="product.info.name.en"
                    :detail-url="product.url"
                    :image="product.scaledImage"
                    :basketidentifier="basketidentifier"
                    :hide-stock="hideStock"
            >
            </product-tile>
        </div>
    </div>
</template>

<script>
import ProductTile from "../product-information/ProductTile";
import {mapActions} from 'vuex';

export default {
    props: {
        productsJson: String,
        containerClass: String,
        basketidentifier: String,
        hideStock: Boolean,
    },
    methods: {
        ...mapActions({
            loadPrice: 'lister/loadSpecialListPrices',
            loadStock: 'lister/loadSpecialListStock'
        })
    },
    computed: {
        products() {
            let products = JSON.parse(this.productsJson);

            products.forEach(function(product, index, products){
               products[index].json = JSON.stringify(product);
            });

            return products;
        },
        productIds() {
            const productIds = [];

            this.products.forEach(function(product){
                productIds.push(product.id);
            });

            return productIds;
        }
    },
    mounted() {
        this.loadPrice(this.productIds);
        this.loadStock(this.productIds);
    },
    components : {
        ProductTile
    }
}
</script>
