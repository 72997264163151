var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row sales-rep no-gutters" }, [
    _c("div", { staticClass: "radio col-2 col-lg-1" }, [
      _c("input", {
        staticClass: "radio-input",
        class: { active: _vm.active },
        attrs: { type: "radio", name: "sales-rep-selector" },
        domProps: { checked: _vm.active, value: _vm.member.code },
        on: { input: _vm.onInput },
      }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "identifier col-5 col-lg-2" }, [
      _vm._v("\n        " + _vm._s(_vm.member.code) + "\n    "),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "name col-5 col-lg-3" }, [
      _vm._v("\n        " + _vm._s(_vm.member.firstname) + "\n    "),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }