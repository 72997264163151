class Dictionary
{
    constructor(json, locale)
    {
        this.locale = locale
        this.dictionary = json
    }

    get(key, returnKeyIfNotFound)
    {
        let foundTranslation = this.dictionary[key]

        if (this.returnKeyWhenNotFound(returnKeyIfNotFound, foundTranslation)) {
            return key
        }

        return foundTranslation
    }

    returnKeyWhenNotFound(returnKeyIfNotFound, translation)
    {
        if (typeof translation !== 'undefined') {
            return false
        }

        if (typeof returnKeyIfNotFound === 'undefined') {
            return true
        }

        return returnKeyIfNotFound
    }

    getCurrentLocale()
    {
        return this.locale
    }
}

export default new Dictionary(dictionary, locale)
