const listerPage = {
    init() {
        Ajax.addCallback('onFinish', 'getProducts', listerPage.onListerLoaded)
    },
    onListerLoaded() {
        let productIds = [];

        $('[data-product-id]').each(function () {
            let id = $(this).data('product-id')

            if (id !== '') {
                productIds.push(id)
            }
        })

        if (productIds.length > 0) {
            window.store.dispatch('lister/loadListPrices', productIds)
            window.store.dispatch('lister/loadStock', productIds)
        }
    }
}

$(document).ready(function() {
    listerPage.init()
})
