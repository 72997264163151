var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasExpired
    ? _c("div", { staticClass: "login-modal-backdrop fadeIn" }, [
        _c("div", { staticClass: "login-modal modal-lg" }, [
          _c("div", { staticClass: "header" }, [
            _c("h3", { staticClass: "m-0" }, [
              _vm._v(_vm._s(_vm.$dictionary("timeout.heading_message"))),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "body" }, [
            _c("div", { staticClass: "formwrapper form-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.password,
                    expression: "password",
                  },
                ],
                staticClass: "form-control",
                class: { "is-invalid": _vm.showError },
                attrs: {
                  type: "password",
                  name: "password",
                  placeholder: _vm.$dictionary(
                    "timeout.password_field_placeholder"
                  ),
                  autofocus: "",
                },
                domProps: { value: _vm.password },
                on: {
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.handleLogin.apply(null, arguments)
                  },
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.password = $event.target.value
                  },
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "invalid-feedback" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.errorMessage) +
                    "\n                "
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "footer" }, [
            _c("a", { staticClass: "mt-2", attrs: { href: "#" } }, [
              _vm._v(
                _vm._s(_vm.$dictionary("timeout.back_to_login_link_text"))
              ),
            ]),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "btn btn-primary mt-2",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.handleLogin.apply(null, arguments)
                  },
                },
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.$dictionary("timeout.button_text")) +
                    "\n            "
                ),
              ]
            ),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }