var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isAustralian
    ? _c("div", { staticClass: "toggler ml-3" }, [
        _c("div", { staticClass: "pr-1" }, [
          _vm._v(_vm._s(_vm.$dictionary("catalog.rrp"))),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "d-flex flex-row justify-start align-items-center" },
          [
            _c("label", { staticClass: "switch", attrs: { for: "switch" } }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.isChecked,
                    expression: "isChecked",
                  },
                ],
                attrs: { type: "checkbox", id: "switch" },
                domProps: {
                  checked: Array.isArray(_vm.isChecked)
                    ? _vm._i(_vm.isChecked, null) > -1
                    : _vm.isChecked,
                },
                on: {
                  change: [
                    function ($event) {
                      var $$a = _vm.isChecked,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.isChecked = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.isChecked = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.isChecked = $$c
                      }
                    },
                    function ($event) {
                      return _vm.handleCheck($event)
                    },
                  ],
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "slider round" }),
            ]),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }