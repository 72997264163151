var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "stock col-1 p-0" }, [
    _c("div", { staticClass: "stock-sign", class: _vm.stockClass }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }