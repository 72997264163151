import Basket from "./Basket";

class BasketManager {
    constructor(isNewBasket) {
        this.baskets = {};
        this.activeBasket = null;
        this.isNewBasket = isNewBasket;
    }

    add(basket) {
        this.baskets[basket.identifier] = basket;
        if (!this.activeBasket) {
            this.setActiveBasket(basket);
        }
    }

    get(basketIdentifier) {
        var basket = this.baskets[basketIdentifier];
        if (!basket) {
            basket = new Basket(basketIdentifier);
            if (!this.isNewBasket) {
                basket.loadInitialBasket();
            }
            this.add(basket);
        }
        return basket;
    }

    reload() {
        for (let basketKey in this.baskets) {
            this.baskets[basketKey].loadInitialBasket();
        }
    };

    setActiveBasket(basket) {
        this.activeBasket = basket;
    }

    getActiveBasket(basket) {
        return this.activeBasket;
    }
}

export default BasketManager;