<template>
    <div class="stock" :class="(stockTranslation) ? '':'loading'">
        <template v-if="stockTranslation !== null">
            <div v-if="showTruckIndicator">
                <i class="icon icon-truck-solid mr-1"></i> {{ $dictionary('stock.' + stockTranslation) }}
            </div>
            <div v-else class="d-flex mt-1">
                <div class="stock-sign d-inline-block" :class="stockClass"></div>
                <span class="ml-2">{{ $dictionary('stock.' + stockTranslation) }}</span>
            </div>
        </template>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';

    export default {
        props: {
            artno: String
        },
        computed: {
            ...mapGetters('lister', [
                'stock'
            ]),
            showTruckIndicator() {
              if (locale !== 'en_AU') {
                  return true;
              }
              return false;
            },
            stockTranslation() {
                if (this.stock.length === 0) {
                    return null
                }

                if (! this.stock.hasOwnProperty(this.artno)) {
                    return null
                }

                return this.stock[this.artno]
            },
            stockClass() {
                switch(this.stockTranslation) {
                    case 'in_stock':
                        return 'in-stock'
                        break;
                    case 'out_of_stock':
                        return 'out-of-stock'
                        break;
                    default:
                        return 'warning-stock'
                }
            }
        }
    }
</script>
