<template>
    <div class="toggler ml-3" v-if="isAustralian">
        <div class="pr-1">{{ $dictionary('catalog.rrp') }}</div>
        <div class="d-flex flex-row justify-start align-items-center">
            <label class="switch" for="switch">
                <input type="checkbox" id="switch" v-model="isChecked" @change="handleCheck($event)">
                <span class="slider round"></span>
            </label>
        </div>
    </div>
</template>

<script>
    import coreHelper from '@core/js/helpers'

    export default {
        props: {
            language: String,
            basketidentifier: String,
            basketUrl: String,
            isAustralian: Boolean
        },
        data() {
            return {
                isChecked: {
                    default: false,
                    type: Boolean
                }
            };
        },
        methods: {
            dictionary(key) {
                return coreHelper.dictionary.get(key)
            },
            handleCheck() {
                store.commit('toggleRecommendedRetailPrice');
            }
        },
        created() {
            if (this.isAustralian) {
                this.isChecked = store.state.showRecommendedRetailPrice;
            } else {
                store.commit('disableRecommendedRetailPrice');
            }
        }
    }
</script>
