import Axios from 'axios'

export default {
    search(keyword, cancelToken) {
        return Axios.post('/api/quicksearch/search', {
            keyword
        },{
            cancelToken
        })
    }
}
