var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showError
    ? _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12 mt-2" }, [
          _c("div", { staticClass: "alert alert-danger" }, [
            _vm._v(_vm._s(_vm.message)),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }