import Axios from 'axios'

export default {
    getStockForProducts(products, cancelToken) {
        if (Array.isArray(products)) {
            products = products.join(',')
        }

        return Axios.get('/api/stock/stock?products=' + products, {
            cancelToken
        })
    },
    getStockForSalesProducts(products, cancelToken) {
        if (Array.isArray(products)) {
            products = products.join(',')
        }

        return Axios.get('/api/stock/sales-stock?products=' + products, {
            cancelToken
        })
    }
}
