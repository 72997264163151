import Axios from 'axios'

export default {
    lockSession(member) {
        return Axios.post('/api/personal-account/lock-session', {
            member
        })
    },
    unLockSession(member, customer, password) {
        return Axios.post('/api/personal-account/unlock-session', {
            member,
            customer,
            password
        })
    }
}
