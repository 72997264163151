import BasketClient from './BasketClient.js';
import BasketLine from './BasketLine.js';
import {serverBus} from "../../js/serverbus";

class Basket {
    constructor(basketIdentifier)
    {
        var language = document.documentElement.lang;
        var country = document.documentElement.country;
        var locale;
        if (country) {
            locale = language + "-" + country;
        } else {
            locale = language + "-" + language;
        }

        this.identifier = basketIdentifier;
        this.basketClient = new BasketClient('api/basket/', basketIdentifier, locale);
    }

    addLine(item, qty)
    {
        this.basketClient.postLine(item, qty, this.processBasketResult, this);
    }

    set(item, qty)
    {
        if (qty > 0) {
            this.basketClient.putLine(this.itemLines[item].getIdentifier(), qty, this.processBasketResult, this);
        } else {
            this.basketClient.deleteLine(this.itemLines[item].getIdentifier(), this.processBasketResult, this);
        }
    }

    removeLine(item)
    {
        this.basketClient.deleteLine(this.itemLines[item].getIdentifier(), this.processBasketResult, this);
    }

    clearLines()
    {
        this.basketClient.clearLines(this.processBasketResult, this);
    }

    setMetaData(item, metaData)
    {
        if (this.itemLines[item] !== undefined) {
            this.basketClient.postLineMetaData(this.itemLines[item].getIdentifier(), metaData, this.processBasketResult, this);
        }
    }

    fireChangedEvent()
    {
        serverBus.$emit('basket.changed', this);
    }

    getQuantityForItem(item)
    {
        if (this.itemLines) {
            if (this.itemLines[item]) {
                return this.itemLines[item].getQuantity();
            }
        }

        return 0;
    }

    getLineCount()
    {
        var size = 0;
        for (var key in this.lines) {
            if (this.lines.hasOwnProperty(key)) {
                size++;
            }
        }
        return size;
    }

    getSubtotal()
    {
        return this.subtotalPrice;
    }

    getSubtotalWithVat()
    {
        return this.subtotalPriceWithVat;
    }

    getTotal()
    {
        return this.totalPrice;
    }

    getTaxes()
    {
        return this.taxes;
    }

    getGrandTotal()
    {
        return this.grandTotal;
    }

    loadInitialBasket()
    {
        this.basketClient.getBasket(this.processBasketResult, this);
    }

    processBasketResult(result)
    {
        this.updateLines(result.lines);
        this.updateCombiLines(result.combinationLines);
        this.updateSubtotalLines(result.subtotalLines);
        this.updateTotalLines(result.totalLines);
        this.updateMessages(result.messages);
        this.updateSubtotalPrice(result.subtotalPrice);
        this.updateTotalPrice(result.totalPrice);
        if (result.totalPrice !== undefined) {
            this.updateTaxes(result.totalPrice.vat);
        } else {
            this.updateTaxes();
        }
        this.updateGrandTotal(result.grandTotal);

        this.fireChangedEvent();
    }

    updateLines(lines)
    {
        this.lines = {};
        this.itemLines = {};
        if (lines !== undefined) {
            for (var i in lines) {
                var line = this.createLine(lines[i]);
                this.lines[line.getIdentifier()] = line;
                this.itemLines[line.productInfo.identifier] = line;
            }
        }
    }

    updateCombiLines(combinationLines)
    {
        this.combiLines = {};
        this.relatedLines = {};
        if (combinationLines !== undefined) {
            for (var i in combinationLines) {
                var combiLine = this.createLine(combinationLines[i]);
                this.combiLines[combiLine.getIdentifier()] = combiLine;

                for (var j in combinationLines[i].relatesTo) {
                    var relatedLine = combinationLines[i].relatesTo[j];
                    this.relatedLines[relatedLine] = combiLine;
                    this.lines[relatedLine].addRelatedLine(combiLine);
                    break;
                }
            }
        }
    }

    updateSubtotalLines(subtotalLines)
    {
        this.subtotalLines = [];
        if (subtotalLines !== undefined) {
            for (var i in subtotalLines) {
                this.subtotalLines.push(this.createSubtotalLine(subtotalLines[i]));
            }
        }
    }

    updateTotalLines(totalLines)
    {
        this.totalLines = [];
        if (totalLines !== undefined) {
            for (var i in totalLines) {
                this.totalLines.push(this.createTotalLine(totalLines[i]));
            }
        }
    }

    updateMessages(messages)
    {
        this.messages = [];
        if (messages !== undefined) {
            for (var i in messages) {
                this.messages.push(this.createMessage(messages[i]));
            }
        }
    }

    updateSubtotalPrice(subtotalPrice)
    {
        if (subtotalPrice !== undefined) {
            this.subtotalPrice = subtotalPrice.amount;
            this.subtotalPriceWithVat = subtotalPrice.amountWithVat;
        }
    }

    updateTotalPrice(totalPrice)
    {
        if (totalPrice !== undefined) {
            this.totalPrice = totalPrice.amount;
            this.totalPriceWithVat = totalPrice.amountWithVat;
        }
    }

    updateTaxes(taxes)
    {
        this.taxes = taxes;
    }

    updateGrandTotal(grandTotal)
    {
        this.grandTotal = grandTotal;
    }

    createLine(lineData)
    {
        return new BasketLine(
            lineData.identifier,
            lineData.productInfo,
            lineData.quantity,
            lineData.unitPrice,
            lineData.totalPrice,
            lineData.promotion,
            lineData.messages,
            lineData.metadata,
            []
        );
    }

    createSubtotalLine(subtotalLineData)
    {
        return subtotalLineData;
    }

    createTotalLine(totalLineData)
    {
        return totalLineData;
    }

    createMessage(messageData)
    {
        return messageData;
    }
}

export default Basket;
