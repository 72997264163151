<template>
    <div class="col-12 sales-rep-selection-widget">
        <div class="row">
            <div class="col-12">
                <legend>{{ dictionary('sales-rep-selection.heading') }}</legend>
                <p class="text-muted">{{ dictionary('sales-rep-selection.subtitle') }}</p>
            </div>
        </div>
        <div class="row">
            <div class="sales-rep-selection col-12">
                <sales-rep-selection-item
                    v-for="member in members"
                    :member="member"
                    :key="member.code"
                    @sales-rep-selected="setSelectedSalesRep"
                />
            </div>
            <div class="w-100 d-flex justify-content-end my-4">
                <button
                    :disabled="chosenMember == null || submitted"
                    @click="selectSalesRep"
                    class="button btn btn-primary ripple btn btn-primary ripple"
                >
                    {{ dictionary('sales-rep-selection.select.button') }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import coreHelper from '@core/js/helpers'
import SalesRepSelectionItem from '@/components/sales-rep-selection/SalesRepSelectionItem'
import api from '@/api'

export default {
    name: "SalesRepSelection",
    components: {SalesRepSelectionItem},
    props: {
        memberJson: {
            type: String
        }
    },
    data: () => ({
        chosenMember: null,
        submitted: false
    }),
    computed: {
        members() {
            return JSON.parse(this.memberJson)
        },
    },
    methods: {
        dictionary(key) {
            return coreHelper.dictionary.get(key)
        },
        async selectSalesRep() {
            this.submitted = true
            await api.salesRepSelection.setCode(this.chosenMember.code)
            .then(({data}) => {
                window.location.href = window.location.origin + data.data['redirect-to']
            })
            this.submitted = false
        },
        setSelectedSalesRep(member) {
            this.chosenMember = member
        }
    }
}
</script>