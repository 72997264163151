var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "stock", class: _vm.stockTranslation ? "" : "loading" },
    [
      _vm.stockTranslation !== null
        ? [
            _vm.showTruckIndicator
              ? _c("div", [
                  _c("i", { staticClass: "icon icon-truck-solid mr-1" }),
                  _vm._v(
                    " " +
                      _vm._s(_vm.$dictionary("stock." + _vm.stockTranslation)) +
                      "\n        "
                  ),
                ])
              : _c("div", { staticClass: "d-flex mt-1" }, [
                  _c("div", {
                    staticClass: "stock-sign d-inline-block",
                    class: _vm.stockClass,
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "ml-2" }, [
                    _vm._v(
                      _vm._s(_vm.$dictionary("stock." + _vm.stockTranslation))
                    ),
                  ]),
                ]),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }