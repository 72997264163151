import Vuex from 'vuex'
import lister from './modules/lister'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        showRecommendedRetailPrice: localStorage.getItem('showRecommendedRetailPrice') === 'true'
    },
    modules: {
        lister
    },
    mutations: {
        disableRecommendedRetailPrice()
        {
            localStorage.setItem('showRecommendedRetailPrice', 'false')
        },
        toggleRecommendedRetailPrice()
        {
            this.state.showRecommendedRetailPrice = !this.state.showRecommendedRetailPrice;
            localStorage.setItem('showRecommendedRetailPrice', this.state.showRecommendedRetailPrice)
        }
    }
})
