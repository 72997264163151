import api from '@/api'

export default {
    namespaced: true,
        state() {
            return {
                prices: [],
                stock: []
            }
    },
    getters: {
        prices: (state) => {
            return state.prices
        },
        stock: (state) => {
            return state.stock
        }
    },
    actions: {
        loadListPrices({commit}, productIds) {
            return api.pricing.listPriceForProducts(productIds, '').then((response) => {
                commit('setPrices', { prices: response.data.data })
            })
        },
        loadStock({commit}, productIds) {
            return api.stock.getStockForSalesProducts(productIds, '').then((response) => {
                commit('setStock', { stock: response.data.data })
            })
        },
        loadSpecialListPrices({commit}, productIds) {
            return api.pricing.listPriceForProducts(productIds, '').then((response) => {
                commit('appendPrices', { prices: response.data.data })
            })
        },
        loadSpecialListStock({commit}, productIds) {
            return api.stock.getStockForSalesProducts(productIds, '').then((response) => {
                commit('appendStock', { stock: response.data.data })
            })
        }
    },
    mutations: {
        setPrices(state, payload) {
            state.prices = payload.prices
        },
        setStock(state, payload) {
            state.stock = payload.stock
        },
        appendPrices(state, payload) {
            if (this.state.stock !== undefined) {
                state.prices = this.state.prices.concat(payload.prices);
            } else {
                state.prices = payload.prices
            }
        },
        appendStock(state, payload) {
            if (this.state.stock !== undefined) {
                state.stock = this.state.stock.concat(payload.stock);
            } else {
                state.stock = payload.stock
            }

        }
    }
}
