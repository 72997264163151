var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "row customer no-gutters",
      class: [_vm.active ? _vm.activeClass : ""],
    },
    [
      _c("div", { staticClass: "radio col-2 col-lg-1" }, [
        _c("input", {
          staticClass: "radio-input",
          class: [_vm.active ? _vm.activeClass : ""],
          attrs: { type: "radio", name: "customerselector" },
          domProps: { checked: _vm.active, value: _vm.customer.id },
          on: { input: _vm.onInput },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "identifier col-5 col-lg-2" }, [
        _vm._v("\n        " + _vm._s(_vm.customer.identifier) + "\n    "),
      ]),
      _vm._v(" "),
      _c("div", {
        staticClass: "name col-5 col-lg-3",
        domProps: { innerHTML: _vm._s(_vm.customer.name) },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "postal col-sm-10 offset-2 offset-lg-0 col-lg-1" },
        [_vm._v("\n        " + _vm._s(_vm.customer.postal) + "\n    ")]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "street col-sm-10 offset-2 offset-lg-0 col-lg" },
        [_vm._v("\n        " + _vm._s(_vm.customer.street) + "\n    ")]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "city col-sm-10 offset-2 offset-lg-0 col-lg-2" },
        [_vm._v("\n        " + _vm._s(_vm.customer.city) + "\n    ")]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }