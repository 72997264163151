<template>
    <div class="login-modal-backdrop fadeIn" v-if="hasExpired">
        <div class="login-modal modal-lg">
            <div class="header">
                <h3 class="m-0">{{ $dictionary('timeout.heading_message') }}</h3>
            </div>
            <div class="body">
                <div class="formwrapper form-group">
                    <input
                        v-model="password"
                        type="password"
                        name="password"
                        class="form-control"
                        :class="{'is-invalid': showError}"
                        :placeholder="$dictionary('timeout.password_field_placeholder')"
                        @keydown.enter="handleLogin"
                        autofocus
                    >
                    <div class="invalid-feedback">
                        {{ errorMessage }}
                    </div>
                </div>
            </div>

            <div class="footer">
                <a href="#" class="mt-2">{{ $dictionary('timeout.back_to_login_link_text') }}</a>
                <a href="#" class="btn btn-primary mt-2" @click.prevent="handleLogin">
                    {{ $dictionary('timeout.button_text') }}
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    import api from '@/api'

    export default {
        props: {
            expireAfter: Number,
            memberId: String,
            customerId: String
        },
        data() {
            return {
                idleTime: 0,
                password: '',
                showError: false,
                invalidPassword: false
            }
        },
        watch: {
            hasExpired(newValue, oldValue) {
                if (newValue === true) {
                    api.personalAccount.lockSession(this.memberId)
                }
            }
        },
        computed: {
            hasExpired() {
                return this.idleTime >= this.expireAfter
            },
            errorMessage() {
                if (this.invalidPassword) {
                    return this.$dictionary('timeout.invalid_password_message')
                }

                return this.$dictionary('timeout.password_field_error_message')
            }
        },
        methods: {
            handleTimeOut() {
                this.idleTime += 1
            },
            handleLogin() {
                if (this.password.length === 0) {
                    this.showError = true
                    return
                }

                api.personalAccount.unLockSession(
                    this.memberId,
                    this.customerId,
                    this.password
                ).then((result) => {
                    this.showError = false
                    this.password = ''
                    this.idleTime = 0
                }).catch((error) => {
                    this.password = ''
                    this.invalidPassword = true
                    this.showError = true
                })
            },
            startTimeOutCounter() {
                setInterval(this.handleTimeOut, 1000)
            },
            resetIdleTime() {
                if (this.hasExpired) {
                    return
                }

                this.idleTime = 0
            }
        },
        created() {
            this.startTimeOutCounter()

            const that = this
            document.addEventListener('keydown', function () {
                that.resetIdleTime()
            })
            document.addEventListener('mousedown', function () {
                that.resetIdleTime()
            })
        }
    }
</script>
