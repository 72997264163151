export default {
    formatPrice(number, decimals, decimalPoint, thousandsSeporator) {
        var decimalPoint = decimalPoint == undefined ? "." : decimalPoint;
        var thousandsSeporator = thousandsSeporator == undefined ? "," : thousandsSeporator;
        var minusSign = number < 0 ? "-" : "";
        var i = String(parseInt(number = Math.abs(Number(number) || 0).toFixed(decimals)));
        var j = (j = i.length) > 3 ? j % 3 : 0;

        return minusSign + (j ? i.substr(0, j) + thousandsSeporator : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousandsSeporator) + (decimals ? decimalPoint + Math.abs(number - i).toFixed(decimals).slice(2) : "");
    }
}
