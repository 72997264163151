import Axios from 'axios';

export default {
    updateRemark(basketLineIdentifier, remark) {
        return Axios.post('/api/su-basket/line/' + basketLineIdentifier + '/remark', {
            basketline: basketLineIdentifier,
            remark: remark
        })
    },
    updateDeliveryDate(basketLineIdentifier, deliveryDate) {
        return Axios.post('/api/su-basket/line/' + basketLineIdentifier + '/delivery_date', {
            basketline: basketLineIdentifier,
            delivery_date: deliveryDate
        })
    },
    updateBasket(identifier, poNumber, partialDelivery, shippingAddressId) {
        return Axios.post('/api/su-basket/basket/' + identifier, {
            identifier,
            po_number: poNumber,
            partial_delivery: partialDelivery,
            shipping_address_id: shippingAddressId
        })
    }
}
