var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "customer-selection col-12" }, [
      _c("div", { staticClass: "row heading no-gutters" }, [
        _c("div", { staticClass: "radio col" }),
        _vm._v(" "),
        _c("div", { staticClass: "identifier col-lg-3" }, [
          _vm._v(
            "\n                " +
              _vm._s(_vm.$dictionary("ordering.ship_to.name")) +
              "\n            "
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "name col-8 col-lg-3" }, [
          _vm._v(
            "\n                " +
              _vm._s(_vm.$dictionary("ordering.ship_to.address")) +
              "\n            "
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "postal col-3 offset-1 col-lg-1" }, [
          _vm._v(
            "\n                " +
              _vm._s(_vm.$dictionary("ordering.ship_to.postal")) +
              "\n            "
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "street col-4 col-lg" }, [
          _vm._v(
            "\n                " +
              _vm._s(_vm.$dictionary("ordering.ship_to.city")) +
              "\n            "
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "city col-3 col-lg-2" }, [
          _vm._v(
            "\n                " +
              _vm._s(_vm.$dictionary("ordering.ship_to.country")) +
              "\n            "
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "customer-selection col-12" },
      _vm._l(_vm.addresses, function (address) {
        return _c(
          "div",
          {
            key: "ship_to_address" + address.id,
            staticClass: "row customer no-gutters",
          },
          [
            _c("div", { staticClass: "radio col" }, [
              _c("input", {
                staticClass: "radio-input",
                attrs: { type: "radio", name: "address_id" },
                domProps: {
                  value: address.id,
                  checked: _vm.selectedAddressId == address.id,
                },
                on: {
                  change: function ($event) {
                    return _vm.$emit("input", address.id)
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "identifier col-3 col-lg-3" }, [
              _vm._v(
                "\n                " + _vm._s(address.name) + "\n            "
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "name col-8 col-lg-3" }, [
              _vm._v(
                "\n                " + _vm._s(address.street) + "\n            "
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "postal col-3 offset-1 col-lg-1" }, [
              _vm._v(
                "\n                " + _vm._s(address.postal) + "\n            "
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "street col-4 col-lg" }, [
              _vm._v(
                "\n                " + _vm._s(address.city) + "\n            "
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "city col-3 col-lg-2" }, [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.countryTranslation(address.country)) +
                  "\n            "
              ),
            ]),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }