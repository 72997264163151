<template>
    <div class="container p-0 open-orders-widget">

        <h1>{{ dictionary('reports.order_overview') }}</h1>
        <div class="row open-order-overview my-3">
            <div class="col-md-6 mb-3">
                <input type="text" v-model="orderNumber" class="form-control"
                       :placeholder="$dictionary('reports.order_number')">
            </div>
            <div class="col-md-6 mb-3">
                <input type="text" v-model="reference" class="form-control"
                       :placeholder="$dictionary('reports.reference')">
            </div>
            <div class="col-md-6 mb-3">
                <input type="text" v-model="materialNumber" class="form-control"
                       :placeholder="$dictionary('reports.material_number')">
            </div>
            <div class="col-md-6 mb-3">
                <select class="form-control" v-model="customer">
                    <option v-for="customer in customers" :value="customer.account_number">{{customer.account_number}} | <span v-html="customer.name"></span></option>
                </select>
            </div>
            <div class="col-md-6 mb-3">
                <label class="form-label d-inline checkbox-container bigger" for="onlyShowOpenOrders" title="">{{$dictionary('reports.open_orders')}}
                    <input type="checkbox" id="onlyShowOpenOrders" v-model="onlyShowOpenOrders" :placeholder="$dictionary('reports.open_orders')">
                    <span class="checkmark bigger"></span>
                </label>
            </div>
        </div>


        <!-- ORDER DATE -->
        <h1>{{ dictionary('reports.order_date') }}</h1>
        <div class="row open-order-date my-3">
            <div class="col-md-6 mb-3">
                <flat-pickr
                        v-model="date_from"
                        :config="config"
                        class="form-control input_date"
                        :placeholder="$dictionary('reports.basket.date_from')"
                        name="date_from"/>
            </div>
            <div class="col-md-6 mb-3">
                <flat-pickr
                        v-model="date_to"
                        :config="config"
                        class="form-control input_date"
                        :placeholder="$dictionary('reports.basket.date_to')"
                        name="date_to"/>
            </div>
        </div>

        <h1>{{ dictionary('reports.order_shipping_date') }}</h1>
        <div class="row open-order-shipping_date my-3">
            <div class="col-md-6 mb-3">
                <flat-pickr
                        v-model="date_shipping_from"
                        :config="config"
                        class="form-control input_date"
                        :placeholder="$dictionary('reports.basket.date_from')"
                        name="date_from"/>
            </div>
            <div class="col-md-6 mb-3">
                <flat-pickr
                        v-model="date_shipping_to"
                        :config="config"
                        class="form-control input_date"
                        :placeholder="$dictionary('reports.basket.date_to')"
                        name="date_to"/>
            </div>
        </div>

        <div id="loading_indicator" v-if="searching">
            <div class="centered">
                <svg version="1.1" id="loading-circle" xmlns="http://www.w3.org/2000/svg"
                     xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                     viewBox="0 0 75.6 75.6" style="enable-background:new 0 0 75.6 75.6;" xml:space="preserve">
                <g>
                    <g>
                        <path class="ring ring1" d="M20.1,70.4C8,64,0.4,51.5,0.4,37.8C0.4,17.5,16.9,1,37.2,1c11.5,0,22.1,5.2,29.2,14.4l-3.7,2.9
                            c-6.1-8-15.4-12.5-25.4-12.5C19.5,5.7,5.1,20.1,5.1,37.8c0,12,6.6,22.9,17.2,28.4C22.3,66.2,20.1,70.4,20.1,70.4z"/>
                        <path class="ring ring3" d="M37.2,25.3c-6.9,0-12.5,5.6-12.5,12.5c0,6.9,5.6,12.5,12.5,12.5c6.9,0,12.5-5.6,12.5-12.5
                            C49.7,30.9,44.1,25.3,37.2,25.3 M37.2,61.6c-13.1,0-23.8-10.7-23.8-23.8C13.4,24.6,24,14,37.2,14C50.3,14,61,24.6,61,37.8
                            C61,50.9,50.3,61.6,37.2,61.6"/>
                        <path class="ring ring2" d="M37.2,67.4c-0.3,0-0.7,0-1,0c-10.4-0.3-19.6-5.9-24.8-15l2.7-1.6C18.8,59,27,64,36.3,64.3
                            c14.6,0.5,26.9-11,27.4-25.6c0.4-11.5-6.7-21.9-17.5-25.8l1.1-3c12.1,4.4,20,16,19.6,28.9C66.3,54.8,53.1,67.4,37.2,67.4"/>
                    </g>
                </g>
            </svg>

                <span class="load-text mt-3">{{ dictionary('catalog.preloader_text') }}</span>
            </div>
        </div>

        <a href="#" class="btn btn-primary mb-5" @click.prevent="getReports">{{ dictionary('reports.show_report') }}</a>
        <h1
                v-if="emptyResponse && this.error === null"
                v-html="dictionary('login.customer_select.no_results_found')"
        ></h1>
        <h1
                v-else
                v-html="this.error"
        ></h1>
        <div v-if="hasSearched && !emptyResponse">
            <!-- SUMMARY -->
            <h1>{{ dictionary('reports.summary') }}</h1>
            <div class="row open-order-summary my-3">
                <div class="col-md-12">
                    <table class="table">
                        <tbody>
                        <tr>
                            <td>{{ dictionary('reports.number_of_open_orders') }}</td>
                            <td>{{ this.data.total_open_orders }}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="add-to-basket d-flex align-items-center justify-content-center">
                <a href="#" class="btn btn-secondary mr-4"
                   @click.prevent="showDetails">{{ dictionary('reports.show_details') }}</a>
                <a href="#" class="btn btn-secondary"
                    @click.prevent="getExelFile">{{ dictionary('reports.export_to_excel') }}</a>
            </div>
        </div>

        <!-- SHOW DETAILS POPUP -->
        <div class="modal" tabindex="-1" role="dialog" id="open-orders-modal">
            <div class="modal-dialog modal-full-width" role="document">
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="modal-close">
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <table class="table striped">
                            <thead>
                            <tr>
                                <th>{{ dictionary('reports.modal.order') }}</th>
                                <th>{{ dictionary('reports.modal.customer') }}</th>
                                <th>{{ dictionary('reports.modal.brand') }}</th>
                                <th>{{ dictionary('reports.modal.material_number') }}</th>
                                <th>{{ dictionary('reports.modal.description') }}</th>
                                <th>{{ dictionary('reports.modal.quantity') }}</th>
                                <th>{{ dictionary('reports.modal.info') }}</th>
                            </tr>
                            </thead>

                            <tbody>
                            <tr v-for="(item) in data.open_orders">
                                <td>
                                    <table class="table unstripped">
                                        <tr>
                                            <td class="bold">{{ dictionary('reports.modal.order') }}</td>
                                            <td>{{ item.invoice_number }}</td>
                                        </tr>
                                        <tr>
                                            <td class="bold no-break pr-1">{{
                                                    dictionary('reports.modal.order_number')
                                                }}
                                            </td>
                                            <td>{{ item.po_number }}</td>
                                        </tr>
                                        <tr>
                                            <td class="bold pr-1">{{ dictionary('reports.modal.date') }}</td>
                                            <td>{{ item.requested_delivery_date }}</td>
                                        </tr>
                                        <tr>
                                            <td class="bold pr-1">{{ dictionary('reports.modal.delivery_block') }}</td>
                                            <td>{{ item.delivery_block }}</td>
                                        </tr>
                                        <tr>
                                            <td class="bold pr-1">{{ dictionary('reports.modal.credit_block') }}</td>
                                            <td>{{ item.credit_block }}</td>
                                        </tr>
                                    </table>
                                </td>

                                <td>
                                    <table class="table unstripped">
                                        <tr>
                                            <td class="bold pr-1">{{ dictionary('reports.modal.customer') }}</td>
                                            <td>{{ item.customer_number }}</td>
                                        </tr>
                                        <tr>
                                            <td class="bold pr-1">{{ dictionary('reports.modal.name') }}</td>
                                            <td>{{ item.customer_name }}</td>
                                        </tr>
                                        <tr>
                                            <td class="bold pr-1">{{ dictionary('reports.modal.city') }}</td>
                                            <td>{{ item.city }}</td>
                                        </tr>
                                        <tr>
                                            <td class="bold pr-1">{{ dictionary('reports.modal.reference') }}</td>
                                            <td>{{ item.order_reference }}</td>
                                        </tr>
                                    </table>
                                </td>

                                <td>
                                    <div>{{ item.brand }}</div>
                                </td>
                                <td>
                                    <div>{{ item.material_number }}</div>
                                </td>
                                <td>
                                    <div>{{ item.material_description }}</div>
                                </td>
                                <td>
                                    <table class="table unstripped">
                                        <tr>
                                            <td class="bold pr-1">{{ dictionary('reports.modal.ordered_quantity') }}</td>

                                            <td>{{ item.ordered_quantity }}</td>
                                        </tr>
                                        <tr>
                                            <td class="bold pr-1">{{ dictionary('reports.modal.allocated_quantity') }}</td>

                                            <td>{{ item.allocated_quantity }}</td>
                                        </tr>
                                        <tr>
                                            <td class="bold pr-1">{{ dictionary('reports.modal.remaining_quantity') }}</td>

                                            <td>{{ item.remaining_quantity }}</td>
                                        </tr>
                                    </table>
                                </td>
                                <td>
                                    <table class="table unstripped">
                                        <tr>
                                            <td class="bold pr-1">{{ dictionary('reports.modal.unit_price') }}</td>
                                            <td>
                                                {{ formatPrice(item.price, item.currency) }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="bold pr-1">{{ dictionary('reports.modal.total') }}</td>
                                            <td>
                                                {{ formatPrice(item.total_price, item.currency) }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="bold pr-1">{{ dictionary('reports.modal.currency') }}</td>

                                            <td>{{ item.currency }}</td>
                                        </tr>
                                    </table>
                                </td>

                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

    </div>

</template>

<script>

import coreHelpers from '@core/js/helpers'
import currency from '../../js/helpers/currency'
import flatPickrHelper from '../../js/helpers/flatPickrHelper'
import api from "@/api";
import Axios from "axios";
import flatPickr from 'vue-flatpickr-component';

export default {
    components: {
        flatPickr
    },
    props: {
        organisationCode: String,
        salesRepNeeded: String,
        salesRepCode: String,
        customers: Array,
        customerCode: String,
        dateFormat: String
    },
    data() {
        return {
            searching: false,
            hasSearched: false,
            cancelToken: null,
            disableButton: true,
            data: [],
            emptyResponse: false,
            config: {
                wrap: false, // set wrap to true only when using 'input-group'
                dateFormat: "Y-m-d",
                altInput: true,
                altFormat: flatPickrHelper.convertToFlatPickrFormat(this.dateFormat),
                disableMobile: "true"
            },
            orderNumber: null,
            reference: null,
            materialNumber: null,
            onlyShowOpenOrders: null,
            date_from: null,
            date_to: null,
            date_shipping_from: null,
            date_shipping_to: null,
            error: null,
            customer: this.customerCode
        }
    },
    methods: {
        getData() {
            const data = {
                'organisationCode': this.organisationCode,
                'orderNumber': this.orderNumber,
                'po': this.reference,
                'materialNumber': this.materialNumber,
                'openOrdersOnly': this.onlyShowOpenOrders,
                'orderDateFrom': this.date_from,
                'orderDateTo': this.date_to,
                'requestedShippingDateFrom': this.date_shipping_from,
                'requestedShippingDateTo': this.date_shipping_to,
                'soldTo': this.customer,
                'dateFormat': this.dateFormat
            };

            if (this.salesRepNeeded) {
                data.salesRep = this.salesRepCode;
            }

            return data;
        },
        dictionary(key) {
            return coreHelpers.dictionary.get(key)
        },
        formatPrice(price, currencyCode) {
            if (price == null || typeof price === 'undefined') {
                return
            }

            return currency.formatPrice(price, currencyCode)
        },
        getReports() {
            this.data = {};
            this.searching = true;
            this.emptyResponse = false;
            this.hasSearched = false;
            this.error = null;
            if (this.cancelToken !== null) {
                this.cancelToken.cancel()
            }

            const cancelToken = Axios.CancelToken.source()
            this.cancelToken = cancelToken

            api.reports.getOpenOrders(this.getData(), cancelToken.token)
                .then((response) => {
                    this.searching = false
                    this.hasSearched = true;
                    if (response.data.data === null) {
                        this.emptyResponse = true;
                        return;
                    }
                    this.data = response.data.data
                }).catch((error) => {
                    this.emptyResponse = true;
                    this.error = error.response.data.msg
                    this.searching = false
            })
        },
        getExelFile() {
            this.searching = true

            api.reports.getOpenOrdersExcel(this.getData())
                    .then((response) => {
                        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
                        const fileLink = document.createElement('a');

                        fileLink.href = fileURL;
                        fileLink.setAttribute(
                                'download',
                                response.headers['content-disposition'].split('filename=')[1]
                        );
                        document.body.appendChild(fileLink);

                        this.searching = false
                        fileLink.click();

                    }).catch(() => {
                this.searching = false
            })
        },
        showDetails() {
            $('#open-orders-modal').modal('show');
        }
    },
    mounted() {
        $(".input_date").change(function () {
            if (this.getAttribute("type") === "date") {
                this.setAttribute("placeholder", "");
            }
        });
    },
}
</script>

