var mobileMenus = {
    init: function() {
        // custom mobile main nav
        $('.hamburger').unbind('click').click(function () {
            $(this).find('.bar-1, .bar-2, .bar-3').toggleClass('active');
            $('.main-menu').toggleClass('show');
        });

        $('.level1 .nav-item').unbind('click').click(function () {
            $('.hamburger').find('.bar-1, .bar-2, .bar-3').toggleClass('active');
            $('.main-menu').toggleClass('show');
        });

        $('.level2 .nav-item').unbind('click').click(function () {
            $('.hamburger').find('.bar-1, .bar-2, .bar-3').toggleClass('active');
            $('.main-menu').toggleClass('show');
        });
    }
}

$(document).ready(function () {
    mobileMenus.init();
});