<template>
    <div class="row no-gutters justify-content-center align-items-center account-details">
 
        <div class="col-12 col-lg-2 bold">{{ member.full_name }}</div>
        <div class="col-12 col-lg-4">{{ member.email }}</div>
        <div class="col-12 col-lg-2 mb-3 mb-lg-0">{{ member.phone }}</div>
        <div class="col-6 col-lg-3">
            <a
                class="btn btn-blue-outline"
                :class="{'is-enabled': member.can_login, 'is-disabled': !member.can_login}"
                @click.prevent="onToggleDisabledClicked"
            >{{ dictionary(buttonText) }}</a>
        </div>
        <div class="col-6 col-lg-1 text-right actions">
            <a href="#" @click.prevent="onEditClicked" class="mr-3">
                <i class="icon icon-edit-regular"></i>
            </a>
            <a href="#" @click.prevent="onDeleteClicked" class="c-color-grey">
                <i class="icon icon-trash-alt-regular"></i>
            </a>
        </div>
    </div>
</template>

<script>
import coreHelpers from '@core/js/helpers'

export default {
    props: {
        member: Object
    },
    computed: {
        buttonText() {
            if (this.member.can_login){
                return 'manage-accounts.disable-employee'
            }

            return 'manage-accounts.enable-employee'
        }
    },
    methods: {
        dictionary(key) {
            return coreHelpers.dictionary.get(key)
        },
        onEditClicked() {
            this.$emit('edit', this.member)
        },
        onDeleteClicked() {
            this.$emit('delete', this.member)
        },
        onToggleDisabledClicked() {
            if (this.member.can_login) {
                this.$emit('disable', this.member)

                return;
            }
            this.$emit('enable', this.member)
        }
    }
}
</script>
