<template>
    <div class="row sales-rep no-gutters">
        <div class="radio col-2 col-lg-1">
            <input type="radio" name="sales-rep-selector" class="radio-input" :checked="active" :class="{'active': active}" :value="member.code" @input="onInput">
        </div>
        <div class="identifier col-5 col-lg-2">
            {{ member.code }}
        </div>
        <div class="name col-5 col-lg-3">
            {{ member.firstname }}
        </div>
    </div>
</template>

<script>
export default {
    name: "SalesRepSelectionItem",
    data: () => ({
        active: false,
        activeClass: 'active',
    }),
    props: {
        member: {
            type: Object
        }
    },
    methods: {
        onInput() {
            this.$emit('sales-rep-selected', this.member)
        }
    }
}
</script>

<style scoped>

</style>