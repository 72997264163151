<template>
    <div class="row">
        <div class="customer-selection col-12">
            <div class="row heading no-gutters">
                <div class="radio col"></div>
                <div class="identifier col-lg-3">
                    {{ $dictionary('ordering.ship_to.name') }}
                </div>
                <div class="name col-8 col-lg-3">
                    {{ $dictionary('ordering.ship_to.address') }}
                </div>
                <div class="postal col-3 offset-1 col-lg-1">
                    {{ $dictionary('ordering.ship_to.postal') }}
                </div>
                <div class="street col-4 col-lg">
                    {{ $dictionary('ordering.ship_to.city') }}
                </div>
                <div class="city col-3 col-lg-2">
                    {{ $dictionary('ordering.ship_to.country') }}
                </div>
            </div>
        </div>

        <div class="customer-selection col-12">
            <div v-for="address in addresses" class="row customer no-gutters" :key="'ship_to_address' + address.id">
                <div class="radio col">
                    <input
                        class="radio-input"
                        type="radio"
                        name="address_id"
                        :value="address.id"
                        @change="$emit('input', address.id)"
                        :checked="selectedAddressId == address.id"
                    >
                </div>
                <div class="identifier col-3 col-lg-3">
                    {{ address.name }}
                </div>
                <div class="name col-8 col-lg-3">
                    {{ address.street }}
                </div>
                <div class="postal col-3 offset-1 col-lg-1">
                    {{ address.postal }}
                </div>
                <div class="street col-4 col-lg">
                    {{ address.city }}
                </div>
                <div class="city col-3 col-lg-2">
                    {{ countryTranslation(address.country) }}
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            addresses: [Object, Array],
            selectedAddressId: [String, Number],
            countries: Object
        },
        methods: {
            countryTranslation(countryCode) {
                if (this.countries[countryCode.toLowerCase()]) {
                    return this.countries[countryCode.toLowerCase()]
                }

                return countryCode
            }
        }
    }
</script>
