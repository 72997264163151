import BaseBasketManager from '@core/js/includes/BasketManager';
import Basket from "./SoundUnitedBasket";

class BasketManager extends BaseBasketManager {
    get(basketIdentifier) {
        var basket = this.baskets[basketIdentifier];
        if (!basket) {
            basket = new Basket(basketIdentifier);
            if (!this.isNewBasket) {
                basket.loadInitialBasket();
            }
            this.add(basket);
        }
        return basket;
    }
}

export default BasketManager;
