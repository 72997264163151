class BasketClient {
    constructor(apiEndpoint, basketIdentifier, locale) {
        this.apiEndpoint = apiEndpoint;
        this.basketIdentifier = basketIdentifier;
        this.locale = locale;
    }

    postLine(productIdentifier, quantity, callback, callbackScope) {
        this.apiCall(
            'POST',
            '/line',
            {
                'productIdentifier': productIdentifier,
                'quantity': quantity
            },
            callback,
            callbackScope
        );
    }

    postLineMetaData(lineIdentifier, metaData, callback, callbackScope) {
        this.apiCall(
            'POST',
            '/line/' + lineIdentifier + '/metadata',
            metaData,
            callback,
            callbackScope
        );
    }

    putLine(lineIdentifier, quantity, callback, callbackScope) {
        this.apiCall(
            'PUT',
            '/line/' + lineIdentifier,
            {
                'quantity': quantity
            },
            callback,
            callbackScope
        );
    }

    deleteLine(lineIdentifier, callback, callbackScope) {
        this.apiCall(
            'DELETE',
            '/line/' + lineIdentifier,
            null,
            callback,
            callbackScope
        );
    }

    clearLines(callback, callbackScope) {
        this.apiCall(
            'DELETE',
            '/lines',
            null,
            callback,
            callbackScope
        );
    }

    getBasket(callback, callbackScope) {
        this.apiCall(
            'GET',
            '',
            null,
            callback,
            callbackScope
        );
    }

    apiCall(method, resource, data, callback, callbackScope) {
        $.ajax({
            url: this.apiEndpoint + this.basketIdentifier + resource,
            type: method,
            headers: { 'accept-language': this.locale },
            data: JSON.stringify(data),
            dataType: "json",
            contentType: "application/json",
            success: function (result) {
                if (callback) {
                    if (!callbackScope) {
                        callbackScope = this;
                    } else {
                        callback.call(callbackScope, result);
                    }
                }
            }
        });
    }
}

export default BasketClient;