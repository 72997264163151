var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "cart-header d-none d-lg-block" }, [
    _c("div", { staticClass: "item-row" }, [
      _c("div", { staticClass: "item-description" }, [
        _vm._v(_vm._s(_vm.$dictionary("ordering.basket.item_description"))),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "item-stock" }, [
        _vm._v(_vm._s(_vm.$dictionary("ordering.basket.stock"))),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "item-remarks" }, [
        _vm._v(_vm._s(_vm.$dictionary("ordering.basket.remarks"))),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "item-quantity text-center" }, [
        _vm._v(_vm._s(_vm.$dictionary("ordering.basket.quantity"))),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "item-list-price" }, [
        _vm._v(_vm._s(_vm.$dictionary("ordering.basket.list_price"))),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "item-discount" }, [
        _vm._v(_vm._s(_vm.$dictionary("ordering.basket.discount"))),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "item-net-price" }, [
        _vm._v(_vm._s(_vm.$dictionary("ordering.basket.net_price"))),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "item-price-subtotal" }, [
        _vm._v(_vm._s(_vm.$dictionary("ordering.basket.subtotal"))),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }