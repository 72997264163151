<template>
    <div class="col-12 customer-selection-widget">
        <div class="row">
            <div class="heading col-12">
                <div class="row">
                    <div class="col-sm-12 col-lg-9 d-flex align-items-center mb-3 mb-lg-0">
                        <ul class="letter-select d-inline-flex flex-wrap list-unstyled mb-0">
                            <li class="letter"
                                v-for="(letter, key) of letters"
                                :key="'letter-' + key"
                                :class="{disabled: isDisabled(letter.toString())}"
                            >
                                <template v-if="isDisabled(letter.toString())">
                                    {{ letter }}
                                </template>
                                <a
                                    v-else
                                    href="#"
                                    @click.prevent="enableLetterSearch(letter.toString())"
                                >{{ letter }}</a>
                            </li>
                        </ul>
                        <a href="#" class="red-link reset-letters d-inline-flex ml-1 mb-2" @click.prevent="disableLetterSearch"><i class="icon icon-redo-alt-solid"></i></a>
                    </div>
                    <div class="search-box col-sm-12 col-lg-3 d-flex align-items-center mb-2 mb-lg-0">
                        <input type="text" v-model="searchWord" :placeholder="dictionary('login.customer_select.search_button_placeholder')">
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="customer-selection col-12">
                <customer-selection-item
                    v-model="selectedCustomer"
                    v-for="customer of filteredCustomers"
                    :key="customer.id"
                    :customer="customer"
                    :active="selectedCustomer ? selectedCustomer.id === customer.id : false"
                ></customer-selection-item>

                <div
                    class="no-results-found mb-4"
                    v-if="filteredCustomers.length === 0"
                    v-html="dictionary('login.customer_select.no_results_found')"
                ></div>
            </div>
        </div>
    </div>
</template>

<script>
    import CustomerSelectionItem from './CustomerSelectionItem'
    import coreHelper from '@core/js/helpers'

    export default {
        props: {
            customersJson: String
        },
        data() {
            return {
                selectedCustomer: null,
                searchWord: '',
                filterLetter: '',
                enabledLetters: []
            }
        },
        computed: {
            customers() {
                return JSON.parse(this.customersJson)
            },
            letters() {
                let symbols = ['@', '!', '"']
                symbols.push(...Array(10).keys())

                // Add all alphabet characters
                for (const x of Array(26).keys()) {
                    symbols.push(String.fromCharCode('A'.charCodeAt(0) + x))
                }

                return symbols
            },
            advancedSearchingEnabled() {
                return this.filterLetter.length > 0 || this.searchWord.length > 0
            },
            filteredCustomers() {
                let customers = this.customers

                if (! this.advancedSearchingEnabled) {
                    return customers
                }

                if (this.filterLetter.length > 0) {
                    customers = customers.filter(function (customer) {
                        return customer.name.substr(0, 1) === this
                    }, this.filterLetter)
                }

                if (this.searchWord.length > 0) {
                    customers = customers.filter(function (customer) {
                        const filterFields = [ 'name', 'identifier', 'street', 'postal', 'city' ]

                        for (const filterField of filterFields) {
                            if (typeof customer[ filterField ] !== 'string') {
                                continue;
                            }
                            if (customer[ filterField ].toLowerCase().includes(this.toLowerCase())) {
                                return true
                            }
                        }

                        return false
                    }, this.searchWord)
                }

                return customers
            }
        },
        methods: {
            dictionary(key) {
                return coreHelper.dictionary.get(key)
            },
            isDisabled(letter) {
                if (this.enabledLetters.includes(letter)) {
                    return false
                }

                return true
            },
            disableLetterSearch() {
                this.filterLetter = ''
            },
            enableLetterSearch(letter) {
                this.filterLetter = letter
            }
        },
        mounted() {
            for (const customer of this.customers) {
                this.enabledLetters.push(customer.name.substr(0,1))
            }
        },
        components: {
            CustomerSelectionItem
        }
    }
</script>
