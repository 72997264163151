<template>
    <div class="stock col-1 p-0">
        <div class="stock-sign" :class="stockClass"></div>
    </div>
</template>

<script>
    export default {
        props: {
            stock: {
                type: Number,
                default: null
            },
            quantity: {
                type: Number,
                default: null
            },
            nlaStatus: {
                type: String,
                default: ''
            },
            estimatedTimeArrival: String,
            basketAmount: Number
        },
        computed: {
            stockClass() {
                if (this.stock === null && this.quantity === null) {
                    return 'out-of-stock'
                }

                if (this.stock === 0 && this.quantity === 0) {
                    return 'out-of-stock'
                }

                if (this.nlaStatus !== '') {
                    return 'warning-stock'
                }

                if (this.estimatedTimeArrival !== '') {
                    return 'warning-stock'
                }

                if (this.stock > this.basketAmount || this.quantity > this.basketAmount) {
                    return 'in-stock'
                }

                return 'warning-stock'
            }
        }
    }
</script>
