<template>
    <div class="container p-0 manage-accounts-widget">
        <manage-customer-account-row
            v-for="member in members"
            :key="'member_' + member.id"
            :member="member"
            @edit="handleEdit"
            @delete="handleDelete"
            @disable="handleDisable"
            @enable="handleEnable"
        ></manage-customer-account-row>
        <div class="row no-gutters mb-5" v-if="members.length === 0">
            <td colspan="5" class="bold">{{ dictionary('manage-accounts.no-accounts-found-message') }}</td>
        </div>
        <a :href="newUrl" class="btn btn-primary">{{ dictionary('manage-accounts.new-account') }}</a>
    </div>
</template>

<script>
import coreHelpers from '@core/js/helpers'
import ManageCustomerAccountRow from './ManageCustomerAccountRow'

export default {
    props: {
        memberJson: String,
        newUrl: String,
        editUrl: String,
        deleteUrl: String,
        enableUrl: String,
        disableUrl: String
    },
    computed: {
        members() {
            return JSON.parse(this.memberJson)
        }
    },
    methods: {
        dictionary(key) {
            return coreHelpers.dictionary.get(key)
        },
        handleEdit(member) {
            window.location.href = this.editUrl + '&id=' + member.id
        },
        handleDelete(member) {
            const confirmationMessage = this.dictionary('manage-accounts.delete-confirmation-message')
            const agreedToDelete = confirm(confirmationMessage)

            if (agreedToDelete) {
                window.location.href = this.deleteUrl + '&id=' + member.id
            }
        },
        handleDisable(member) {
            window.location.href = this.disableUrl + '&id=' + member.id
        },
        handleEnable(member) {
            window.location.href = this.enableUrl + '&id=' + member.id
        }
    },
    components: {
        ManageCustomerAccountRow
    }
}
</script>
