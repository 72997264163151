/**
 This will be build using webpack
 Import / Include all files needed for your custom theme
*/
import "babel-polyfill"
import Axios from 'axios'
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'
import vueDebounce from 'vue-debounce'
import BasketManager from '@/js/basket/SoundUnitedBasketManager';
import dictionary from './helpers/dictionary';
import './helpers/string';

// Add store
import store from './store';
window.store = store;

Vue.use(vueDebounce)
Vue.use(dictionary)

import './pages/lister'
import './pages/footer-menus'
import './pages/mobile-menus'
import './pages/lister-filters'
import './pages/header-search'

const requireComponent = require.context(
        // The relative path of the components folder
        './../components',
        // Whether or not to look in subfolders
        true,
        // The regular expression used to match base component filenames
        /[A-Z]\w+\.(vue|js)$/
)

requireComponent.keys().forEach(fileName => {
    // Get component config
    const componentConfig = requireComponent(fileName)

    // Get PascalCase name of component
    const componentName = upperFirst(
            camelCase(
                    // Gets the file name regardless of folder depth
                    fileName
                            .split('/')
                            .pop()
                            .replace(/\.\w+$/, '')
            )
    )


    // Register component globally
    Vue.component(
            componentName,
            // Look for the component options on `.default`, which will
            // exist if the component was exported with `export default`,
            // otherwise fall back to module's root.
            componentConfig.default || componentConfig
    )
})

Axios.defaults.headers.common['X-CSRF-TOKEN'] = $("meta[name=csrf-token]").attr('content');

if (typeof window.BasketManager === "undefined") {
    window.BasketManager = new BasketManager(isNewBasket)
}

// Initialize vue-components
$('.vue-el').each(function (i, val) {
    new Vue({
        store,
        el: "#" + $(val).attr('id')
    });
    $(val).removeClass('vue-el');
});
