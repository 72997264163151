var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "col-12 sales-rep-selection-widget" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("legend", [
          _vm._v(_vm._s(_vm.dictionary("sales-rep-selection.heading"))),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "text-muted" }, [
          _vm._v(_vm._s(_vm.dictionary("sales-rep-selection.subtitle"))),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "sales-rep-selection col-12" },
        _vm._l(_vm.members, function (member) {
          return _c("sales-rep-selection-item", {
            key: member.code,
            attrs: { member: member },
            on: { "sales-rep-selected": _vm.setSelectedSalesRep },
          })
        }),
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "w-100 d-flex justify-content-end my-4" }, [
        _c(
          "button",
          {
            staticClass: "button btn btn-primary ripple btn btn-primary ripple",
            attrs: { disabled: _vm.chosenMember == null || _vm.submitted },
            on: { click: _vm.selectSalesRep },
          },
          [
            _vm._v(
              "\n                " +
                _vm._s(_vm.dictionary("sales-rep-selection.select.button")) +
                "\n            "
            ),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }