var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container p-0 sales-quickorder-widget" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("h3", [_vm._v(_vm._s(_vm.dictionary("quickorder.title")))]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-9" },
        _vm._l(_vm.errors, function (error) {
          return _c("p", { staticClass: "alert alert-danger" }, [
            _vm._v("\n                " + _vm._s(error) + "\n                "),
            _vm._m(0, true),
          ])
        }),
        0
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-9" }, [
        _c("div", { staticClass: "row heading no-gutters" }, [
          _c("div", { staticClass: "col-4" }, [
            _vm._v(_vm._s(_vm.dictionary("quickorder.material"))),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-3 text-center" }, [
            _vm._v(_vm._s(_vm.dictionary("quickorder.quantity"))),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-2" }, [
            _vm._v(_vm._s(_vm.dictionary("quickorder.unit_price"))),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-2 text-center" }, [
            _vm._v(_vm._s(_vm.dictionary("quickorder.orderable"))),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "quickorder-results col-9" },
        [
          _vm._l(_vm.basketLines, function (basketLine) {
            return _c("sales-quick-order-item", {
              key: basketLine.identifier,
              attrs: {
                item: basketLine,
                basketidentifier: _vm.basketidentifier,
              },
              on: { delete: _vm.deleteItem },
            })
          }),
          _vm._v(" "),
          _c("div", { staticClass: "row result search no-gutters" }, [
            _c("div", { staticClass: "description col-lg-5" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.keyword,
                    expression: "keyword",
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  type: "text",
                  placeholder: _vm.$dictionary("search.search.btn.search"),
                },
                domProps: { value: _vm.keyword },
                on: {
                  keyup: _vm.handleOnSearch,
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.keyword = $event.target.value
                  },
                },
              }),
              _vm._v(" "),
              _vm.items.length > 0
                ? _c("div", { staticClass: "search-dropdown" }, [
                    _c(
                      "ul",
                      { staticClass: "list-unstyled" },
                      _vm._l(_vm.items, function (item) {
                        return _c(
                          "li",
                          {
                            staticClass: "text-truncate",
                            on: {
                              click: function ($event) {
                                return _vm.addToBasket(item)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                                 " +
                                _vm._s(_vm.name(item)) +
                                "\n                             "
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "quantity text-center col-lg-3" }),
            _vm._v(" "),
            _c("div", { staticClass: "grossprice col-lg-2" }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "orderable text-left text-md-center col-lg-2" },
              [
                _c("i", {
                  staticClass: "icon",
                  class: { "icon-spinner-solid c-info-orange": _vm.searching },
                }),
              ]
            ),
          ]),
          _vm._v(" "),
          _vm.searching
            ? _c("div", { staticClass: "searching mb-4" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.dictionary("quickorder.search.searching")) +
                    "\n            "
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "d-flex justify-content-end" }, [
            _c(
              "a",
              {
                staticClass: "btn btn-primary ripple mt-2",
                attrs: { href: _vm.basketUrl },
              },
              [_vm._v(_vm._s(_vm.$dictionary("ordering.basket.viewcart")))]
            ),
          ]),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          type: "button",
          "data-dismiss": "alert",
          "aria-label": "Close",
        },
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }