/**
 * @example: "Say {0} {1}".format("Hello", "World") results in "Say Hello World"
 * @see: https://coderwall.com/p/flonoa/simple-string-format-in-javascript
 */
String.prototype.format = function () {
    let a = this;
    for (let k in arguments) {
        a = a.replace(new RegExp("\\{" + k + "\\}", 'g'), arguments[k]);
    }
    return a
}
