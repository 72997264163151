var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "row no-gutters justify-content-center align-items-center account-details",
    },
    [
      _c("div", { staticClass: "col-12 col-lg-2 bold" }, [
        _vm._v(_vm._s(_vm.member.full_name)),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-lg-4" }, [
        _vm._v(_vm._s(_vm.member.email)),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-lg-2 mb-3 mb-lg-0" }, [
        _vm._v(_vm._s(_vm.member.phone)),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-6 col-lg-3" }, [
        _c(
          "a",
          {
            staticClass: "btn btn-blue-outline",
            class: {
              "is-enabled": _vm.member.can_login,
              "is-disabled": !_vm.member.can_login,
            },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.onToggleDisabledClicked.apply(null, arguments)
              },
            },
          },
          [_vm._v(_vm._s(_vm.dictionary(_vm.buttonText)))]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-6 col-lg-1 text-right actions" }, [
        _c(
          "a",
          {
            staticClass: "mr-3",
            attrs: { href: "#" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.onEditClicked.apply(null, arguments)
              },
            },
          },
          [_c("i", { staticClass: "icon icon-edit-regular" })]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "c-color-grey",
            attrs: { href: "#" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.onDeleteClicked.apply(null, arguments)
              },
            },
          },
          [_c("i", { staticClass: "icon icon-trash-alt-regular" })]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }