var footerMenus = {
    init: function() {

        // custom footer mobile nav
        $('footer').find('.nav').siblings('h3').unbind('click').click(function () {
            $(this).toggleClass('active');
            $(this).parent().find('.nav').slideToggle('fast');
        });
    }
}

$(document).ready(function () {
    footerMenus.init();
});