import Axios from 'axios'

export default {
    getReports(data, cancelToken) {
        return Axios.post('/api/reports/getTurnoverReport', {
            data
        },{
            cancelToken
        })
    },
    getTurnoverExcel(data) {
        return Axios.post('/api/reports/getTurnoverReportExcel', {
            data
        }, {
            responseType: 'blob'
        })
    },
    getOpenOrders(data, cancelToken) {
        return Axios.post('/api/reports/getOpenOrderReport', {
            data
        },{
            cancelToken
        })
    },
    getOpenOrdersExcel(data) {
        return Axios.post('/api/reports/getOpenOrderReportExcel', {
            data
        }, {
            responseType: 'blob'
        })
    },
}
