var headerSearch = {
    init: function() {

        // custom input delete for header search
        $('input.delete').wrap('<span class="delete-icon"></span>').after($('<span>x</span>').click(function() {
            $(this).prev('input').val('').trigger('change').focus();
        }));

        $('.dropdown-menu').on('click', function(event){
            event.stopPropagation();
        });
    }
}

$(document).ready(function () {
    headerSearch.init();
});