export default {
    convertToFlatPickrFormat: function (format) {
        const mappingTable = {
            'd/M/yy': 'j/n/y',                  // 24/7/23
            'M/d/yy': 'n/j/y',                  // 7/24/23
            'd/MM/yy': 'j/m/y',                 // 24/07/23
            'dd-MM-yy': 'd-m-y',                // 24-07-23
            'dd/MM/y': 'd/m/Y',                 // 24/07/2023
            'dd.MM.yy': 'd.m.y',                // 24.07.23
            'dd/MM/yyyy': 'd/m/Y',              // 24/07/2023
            'dd/MM/yy': 'd/m/y'                 // 24/07/23
        };
        if (format in mappingTable) {
            return mappingTable[format];
        }

        return format;
    }
}
