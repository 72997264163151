import Axios from 'axios'

export default {
    createOrder(basketIdentifier, organisationCode, currencyCode, deliveryDate) {
        return Axios.post('/api/checkout/create-order', {
            basket_identifier: basketIdentifier,
            organisation_code: organisationCode,
            currency_code: currencyCode,
            delivery_date: deliveryDate
        })
    }
}
