class BasketLine {
    constructor(identifier, productInfo, quantity, unitPrice, totalPrice, promotion, messages, metaData, relatedLines) {
        this.identifier = identifier;
        this.productInfo = productInfo;
        this.quantity = quantity;
        this.unitPrice = unitPrice;
        this.totalPrice = totalPrice;
        this.promotion = promotion;
        this.messages = messages;
        this.metaData = metaData;
        this.relatedLines = relatedLines;
    }

    getIdentifier() {
        return this.identifier;
    }

    getQuantity() {
        return this.quantity;
    }

    addRelatedLine(line) {
        this.relatedLines.push(line);
    }

    getRelatedLines() {
        return this.relatedLines;
    }

    getMetaData() {
        return this.metaData;
    }
}

export default BasketLine;