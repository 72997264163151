import basket from './basket'
import checkout from './checkout'
import pricing from './pricing'
import quicksearch from './quicksearch'
import stock from './stock'
import reports from './reports'
import personalAccount from './personal-account'
import salesRepSelection from './sales-rep-selection'

export default {
    basket,
    checkout,
    personalAccount,
    pricing,
    quicksearch,
    stock,
    reports,
    salesRepSelection,
}
