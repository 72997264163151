var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "add-to-basket d-flex align-items-center justify-content-center",
    },
    [
      _vm.isOrderable
        ? [
            _c("Transition", { attrs: { name: "fade" } }, [
              _vm.errorMessage
                ? _c(
                    "div",
                    {
                      staticClass:
                        "quantity-message alert alert-warning alert-dismissible show",
                      attrs: { role: "alert" },
                    },
                    [
                      _vm.errorMessageQuantity
                        ? _c("span", [_vm._v(_vm._s(_vm.quantityErrorMessage))])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.errorMessageDelivery
                        ? _c("span", [_vm._v(_vm._s(_vm.deliveryErrorMessage))])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "close",
                          attrs: { type: "button", "aria-label": "Close" },
                          on: {
                            click: function ($event) {
                              _vm.errorMessage = !_vm.errorMessage
                            },
                          },
                        },
                        [
                          _c("span", { attrs: { "aria-hidden": "true" } }, [
                            _vm._v("×"),
                          ]),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
            ]),
            _vm._v(" "),
            _vm.inBasket
              ? _c("div", { staticClass: "quantity-selector d-flex" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-spinner ripple btn-decrease",
                      on: {
                        click: function ($event) {
                          return _vm.decreaseLineQuantity()
                        },
                      },
                    },
                    [_vm._v("-")]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.size,
                        expression: "size",
                      },
                    ],
                    staticClass: "text-center",
                    attrs: { type: "number", min: "0" },
                    domProps: { value: _vm.size },
                    on: {
                      change: function ($event) {
                        return _vm.setBasketQuantity()
                      },
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.size = $event.target.value
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-spinner ripple btn-increase",
                      on: {
                        click: function ($event) {
                          return _vm.increaseLineQuantity()
                        },
                      },
                    },
                    [_vm._v("+")]
                  ),
                ])
              : _c("div", { staticClass: "button-wrapper" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary btn-add-to-cart",
                      class: {
                        "d-none": _vm.inBasket && !_vm.basketUpdated,
                        "animate spaceOutUp": _vm.basketUpdated,
                        "animate fadeIn": !_vm.inBasket,
                        "btn-small": _vm.small,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.addToBasket()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.buttonText) +
                          "\n            "
                      ),
                    ]
                  ),
                ]),
          ]
        : [
            _c("div", { staticClass: "button-wrapper" }, [
              _c(
                "div",
                {
                  staticClass: "btn btn-loading loading",
                  class: {
                    "btn-small": _vm.small,
                    "btn-no-price": _vm.noPrice,
                    "btn-no-nla": _vm.nlaStatus,
                    "btn-no-quantity": !_vm.minimalOrderQuantity,
                    "btn-not-indivisible": !_vm.indivisibleUnit,
                    "btn-no-preloader": _vm.removePreLoader,
                  },
                },
                [_vm._v(_vm._s(_vm.buttonText))]
              ),
            ]),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }