var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container p-0 spares-quicksearch-widget" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("h3", [_vm._v(_vm._s(_vm.dictionary("quicksearch.title")))]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row search-bar" }, [
      _c("div", { staticClass: "col-12 col-md-8 col-lg-3 mb-3 mb-md-0" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.keyword,
              expression: "keyword",
            },
          ],
          staticClass: "form-control",
          attrs: { type: "text", autocomplete: "off" },
          domProps: { value: _vm.keyword },
          on: {
            keyup: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              return _vm.handleOnSearch.apply(null, arguments)
            },
            input: function ($event) {
              if ($event.target.composing) return
              _vm.keyword = $event.target.value
            },
          },
        }),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "quicksearch-btn",
            attrs: { href: "" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.handleOnSearch.apply(null, arguments)
              },
            },
          },
          [_c("i", { staticClass: "icon icon icon-search-solid" })]
        ),
      ]),
      _vm._v(" "),
      _vm.showTable
        ? _c("div", { staticClass: "col-12 col-md-4 col-lg-9 text-right" }, [
            _vm.hasSearched && !_vm.searching && !_vm.hasResults
              ? _c(
                  "a",
                  {
                    staticClass: "btn btn-primary mr-3 text-white",
                    attrs: { id: "mail-modal" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.handleModal.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.dictionary("email.email_button_title")))]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "btn btn-primary",
                class: { disabled: _vm.disableButton },
                attrs: { href: _vm.basketUrl },
              },
              [_vm._v(_vm._s(_vm.dictionary("quicksearch.order_button_title")))]
            ),
          ])
        : _vm._e(),
    ]),
    _vm._v(" "),
    _vm.hasError
      ? _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12 alert-danger" }, [
            _vm._v("\n            " + _vm._s(_vm.error) + "\n        "),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.showTable
      ? _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("div", { staticClass: "row heading no-gutters" }, [
              _c("div", { staticClass: "col-2" }, [
                _vm._v(_vm._s(_vm.dictionary("quicksearch.material"))),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-3" }, [
                _vm._v(_vm._s(_vm.dictionary("quicksearch.description"))),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-2 text-center" }, [
                _vm._v(_vm._s(_vm.dictionary("quicksearch.quantity"))),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-5" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-1 p-0" }, [
                    _vm._v(_vm._s(_vm.$dictionary("ordering.basket.stock"))),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-2" }, [
                    _vm._v(_vm._s(_vm.dictionary("quicksearch.net_price"))),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-2" }, [
                    _vm._v(_vm._s(_vm.dictionary("quicksearch.gross_price"))),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-2 text-center" }, [
                    _vm._v(_vm._s(_vm.dictionary("quicksearch.orderable"))),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-1 text-center" }, [
                    _vm._v(_vm._s(_vm.dictionary("quicksearch.eta"))),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-2 text-center" }, [
                    _vm._v(_vm._s(_vm.dictionary("quicksearch.nla"))),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-2 text-center" }, [
                    _vm._v(_vm._s(_vm.dictionary("quicksearch.order_limit"))),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.showTable
      ? _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "quicksearch-results col-12" },
            [
              _vm._l(_vm.items, function (item) {
                return [
                  _c(
                    "spares-quick-search-item",
                    _vm._b(
                      { key: "quick_search_item_" + item.id },
                      "spares-quick-search-item",
                      {
                        item,
                        language: _vm.language,
                        prices: _vm.prices,
                        stock: _vm.stock,
                        organisationId: _vm.organisationId,
                        basketidentifier: _vm.basketidentifier,
                      },
                      false
                    )
                  ),
                  _vm._v(" "),
                  item.alternatives
                    ? _c("div", { staticClass: "alt-header" }, [
                        _vm._v(
                          _vm._s(_vm.dictionary("quicksearch.alternative"))
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._l(item.alternatives, function (alternative) {
                    return _c(
                      "spares-quick-search-item",
                      _vm._b(
                        {
                          key:
                            "quick_search_alternative_item_" +
                            item.id +
                            "_" +
                            alternative.id,
                        },
                        "spares-quick-search-item",
                        {
                          item: alternative,
                          language: _vm.language,
                          prices: _vm.prices,
                          stock: _vm.stock,
                          organisationId: _vm.organisationId,
                          basketidentifier: _vm.basketidentifier,
                          isAlternative: true,
                        },
                        false
                      )
                    )
                  }),
                ]
              }),
              _vm._v(" "),
              _vm.searching
                ? _c("div", { staticClass: "searching mb-4" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.dictionary("quicksearch.search.searching")) +
                        "\n            "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              !_vm.hasResults && !_vm.searching
                ? _c("div", {
                    staticClass: "no-results-found mb-4",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.dictionary("quicksearch.search.no_results_found")
                      ),
                    },
                  })
                : _vm._e(),
            ],
            2
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.showTable
      ? _c("div", { staticClass: "row bottom" }, [
          _c("div", { staticClass: "col-12 text-right" }, [
            _c(
              "a",
              {
                staticClass: "btn btn-primary",
                class: { disabled: _vm.disableButton },
                attrs: { href: _vm.basketUrl },
              },
              [_vm._v(_vm._s(_vm.dictionary("quicksearch.order_button_title")))]
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }