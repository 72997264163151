import BaseBasketLine from '@core/js/includes/BasketLine'

class BasketLine extends BaseBasketLine {
    constructor(identifier, productInfo, quantity, unitPrice, totalPrice, promotion, messages, metaData, relatedLines, remark, deliveryDate) {
        super(identifier, productInfo, quantity, unitPrice, totalPrice, promotion, messages, metaData, relatedLines)
        this.remark = remark
        this.deliveryDate = deliveryDate
    }

    getRemark() {
        return this.remark
    }

    getDeliveryDate() {
        return this.deliveryDate
    }
}

export default BasketLine;
