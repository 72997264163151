<template>
    <div class="container p-0 spares-quicksearch-widget">
        <div class="row">
            <div class="col-12">
                <h3>{{ dictionary('quicksearch.title') }}</h3>
            </div>
        </div>
        <div class="row search-bar">
            <div class="col-12 col-md-8 col-lg-3 mb-3 mb-md-0">
                <input
                    type="text"
                    class="form-control"
                    autocomplete="off"
                    v-model="keyword"
                    v-on:keyup.enter="handleOnSearch"
                >
                <a href="" @click.prevent="handleOnSearch" class="quicksearch-btn"><i class="icon icon icon-search-solid"></i></a>
            </div>
            <div class="col-12 col-md-4 col-lg-9 text-right" v-if="showTable">
                <a id="mail-modal" class="btn btn-primary mr-3 text-white" v-if="hasSearched && !searching && !hasResults" @click.prevent="handleModal">{{ dictionary('email.email_button_title') }}</a>
                <a :href="basketUrl" :class="{'disabled': disableButton}" class="btn btn-primary">{{ dictionary('quicksearch.order_button_title') }}</a>
            </div>
        </div>
        <div class="row" v-if="hasError">
            <div class="col-12 alert-danger">
                {{ error }}
            </div>
        </div>
        <div class="row" v-if="showTable">
            <div class="col-12">
                <div class="row heading no-gutters">
                    <div class="col-2">{{ dictionary('quicksearch.material') }}</div>
                    <div class="col-3">{{ dictionary('quicksearch.description') }}</div>
                    <div class="col-2 text-center">{{ dictionary('quicksearch.quantity') }}</div>
                    <div class="col-5">
                        <div class="row">
                            <div class="col-1 p-0">{{ $dictionary('ordering.basket.stock') }}</div>
                            <div class="col-2">{{ dictionary('quicksearch.net_price') }}</div>
                            <div class="col-2">{{ dictionary('quicksearch.gross_price') }}</div>
                            <div class="col-2 text-center">{{ dictionary('quicksearch.orderable') }}</div>
                            <div class="col-1 text-center">{{ dictionary('quicksearch.eta') }}</div>
                            <div class="col-2 text-center">{{ dictionary('quicksearch.nla') }}</div>
                            <div class="col-2 text-center">{{ dictionary('quicksearch.order_limit') }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" v-if="showTable">
            <div class="quicksearch-results col-12">
                <template
                        v-for="item of items">
                    <spares-quick-search-item
                            :key="'quick_search_item_' + item.id"
                            v-bind="{
                                item,
                                language,
                                prices,
                                stock,
                                organisationId,
                                basketidentifier
                            }"
                    ></spares-quick-search-item>
                    <div class="alt-header" v-if="item.alternatives">{{ dictionary('quicksearch.alternative') }}</div>
                    <spares-quick-search-item
                            v-for="alternative of item.alternatives"
                            :key="'quick_search_alternative_item_' + item.id + '_'+ alternative.id"
                            v-bind="{
                                item: alternative,
                                language,
                                prices,
                                stock,
                                organisationId,
                                basketidentifier,
                                isAlternative: true
                            }"
                    ></spares-quick-search-item>
                </template>
                <div class="searching mb-4" v-if="searching">
                    {{ dictionary('quicksearch.search.searching') }}
                </div>
                <div
                    class="no-results-found mb-4"
                    v-if="! hasResults && ! searching"
                    v-html="dictionary('quicksearch.search.no_results_found')"
                ></div>
            </div>
        </div>
        <div class="row bottom" v-if="showTable">
            <div class="col-12 text-right">
                <a :href="basketUrl" class="btn btn-primary" :class="{'disabled': disableButton}">{{ dictionary('quicksearch.order_button_title') }}</a>
            </div>
        </div>
    </div>
</template>

<script>
    import SparesQuickSearchItem from './SparesQuickSearchItem'
    import coreHelper from '@core/js/helpers'
    import api from '@/api'
    import Axios from 'axios'

    export default {
        props: {
            language: String,
            basketidentifier: String,
            basketUrl: String,
            sendUrl: String,
            organisationId: String
        },
        created() {
            serverBus.$on('basket.changed', (basket) => {
                this.disableButton = basket.getLineCount() === 0;
            });
        },
        data() {
            return {
                items: [],
                prices: [],
                stock: [],
                searching: false,
                hasSearched: false,
                keyword: '',
                error: '',
                cancelToken: null,
                disableButton: true,
            }
        },
        computed: {
            hasResults() {
                if (this.searching) {
                    return false
                }

                return this.items.length !== 0;
            },
            showTable() {
                if (this.items.length > 0) {
                    return true
                }

                return this.hasSearched
            },
            hasError() {
                if (this.error === '') {
                    return false
                }

                if (typeof this.error !== 'string') {
                    return false
                }

                return this.error.lenght > 0
            },
            productIds() {
                let productIds = []

                for (let item of this.items) {
                    productIds.push(item.id)
                    if (item.alternatives !== undefined) {
                        for (let alternative of item.alternatives) {
                            productIds.push(alternative.id)
                        }
                    }
                }

                return productIds
            }
        },
        methods: {
            dictionary(key) {
                return coreHelper.dictionary.get(key)
            },
            handleOnSearch() {
                this.searching = true
                this.hasSearched = true
                this.items = []
                this.prices = []
                this.stock = []

                if (this.cancelToken !== null) {
                    this.cancelToken.cancel()
                }

                const cancelToken = Axios.CancelToken.source()
                this.cancelToken = cancelToken

                api.quicksearch.search(this.keyword, cancelToken.token)
                .then((response) => {
                    this.items = response.data.data
                    this.searching = false

                    return response
                }).then((response) => {
                    if (response.data.data.length > 0) {
                        this.getPrices(cancelToken.token)
                        this.getStock(cancelToken.token)
                    }
                }).catch((response) => {
                    this.error = response.error
                    this.searching = false
                })
            },
            getPrices(cancelToken) {
                api.pricing.listPriceForProducts(this.productIds, cancelToken).then((response) => {
                    this.prices = response.data.data
                }).catch((error) => {
                    console.error(error)
                })
            },
            getStock(cancelToken) {
                api.stock.getStockForProducts(this.productIds, cancelToken).then((response) => {
                    this.stock = response.data.data
                }).catch((error) => {
                    console.error(error)
                })
            },
            handleModal() {
                window.location.href = this.sendUrl
            }
        },
        components: {
            SparesQuickSearchItem
        }
    }
</script>
