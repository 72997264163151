<template>
    <div class="container p-0 sales-quickorder-widget">
        <div class="row">
            <div class="col-12">
                <h3>{{ dictionary('quickorder.title') }}</h3>
            </div>
        </div>

        <div class="row">
            <div class="col-9">
                <p
                    class="alert alert-danger"
                    v-for="error in errors"
                >
                    {{ error }}
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </p>
            </div>
        </div>

        <!-- HEADER -->
        <div class="row">
            <div class="col-9">
                <div class="row heading no-gutters">
                    <div class="col-4">{{ dictionary('quickorder.material') }}</div>
                    <div class="col-3 text-center">{{ dictionary('quickorder.quantity') }}</div>
                    <div class="col-2">{{ dictionary('quickorder.unit_price') }}</div>
                    <div class="col-2 text-center">{{ dictionary('quickorder.orderable') }}</div>
                </div>
            </div>
        </div>
        <!-- END HEADER -->

        <div class="row">
            <div class="quickorder-results col-9">
                <!-- ITEM RESULTS -->
                <sales-quick-order-item
                    v-for="basketLine in basketLines"
                    :key="basketLine.identifier"
                    :item="basketLine"
                    :basketidentifier="basketidentifier"
                    @delete="deleteItem"
                ></sales-quick-order-item>
                <!-- END ITEM RESULTS -->

                <!-- SEARCH INPUT -->
                <div class="row result search no-gutters">
                    <div class="description col-lg-5">
                        <input
                            type="text"
                            class="form-control"
                            v-model="keyword"
                            v-on:keyup="handleOnSearch"
                            :placeholder="$dictionary('search.search.btn.search')"
                        >

                        <!-- FILL AND SHOW ON SEARCH -->
                        <div class="search-dropdown" v-if="items.length > 0">
                            <ul class="list-unstyled">
                                 <li
                                     v-for="item in items"
                                     @click="addToBasket(item)"
                                     class="text-truncate"
                                 >
                                     {{ name(item) }}
                                 </li>
                            </ul>
                        </div>

                    </div>
                    <div class="quantity text-center col-lg-3"></div>

                    <div class="grossprice col-lg-2"></div>

                    <div class="orderable text-left text-md-center col-lg-2">
                        <i class="icon" :class="{'icon-spinner-solid c-info-orange': searching}"></i>
                    </div>
                </div>
                <!-- END SEARCH INPUT -->

                <div class="searching mb-4" v-if="searching">
                    {{ dictionary('quickorder.search.searching') }}
                </div>

                <div class="d-flex justify-content-end">
                    <a class="btn btn-primary ripple mt-2" :href="basketUrl">{{ $dictionary('ordering.basket.viewcart') }}</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import SalesQuickOrderItem from './SalesQuickOrderItem'
    import coreHelper from '@core/js/helpers'
    import api from '@/api'
    import Axios from 'axios'

    export default {
        props: {
            language: String,
            basketidentifier: String,
            basketUrl: String
        },
        created() {
            serverBus.$on('basket.changed', (basket) => {
                this.basketLines = basket.lines
                this.disableButton = basket.getLineCount() === 0;
            });
        },
        data() {
            return {
                keyword: '',
                basketLines: [],
                items: [],
                errors: [],
                searching: false,
                hasSearched: false,
                cancelToken: null,
            }
        },
        methods: {
            dictionary(key) {
                return coreHelper.dictionary.get(key)
            },
            name(item) {
                if (item.info.name[this.language]) {
                    return `${item.info.artno} ${item.info.name[this.language]}`
                }

                return `${item.info.artno} ${item.info.name.en}`;
            },
            handleOnSearch() {
                this.searching = true
                this.hasSearched = true
                this.items = []
                this.prices = []
                this.stock = []

                if (this.cancelToken !== null) {
                    this.cancelToken.cancel()
                }

                const cancelToken = Axios.CancelToken.source()
                this.cancelToken = cancelToken

                api.quicksearch.search(this.keyword, cancelToken.token)
                    .then(({data}) => {
                        this.items = data.data
                        this.searching = false

                        return response
                    })
                    .catch(({error}) => {
                        this.error = error
                        this.searching = false
                    })
            },
            addToBasket(item) {
                const basket = window.BasketManager.get(this.basketidentifier);
                basket.addLine(item.id, 1);
                this.keyword = '';
                this.items = [];
            },
            deleteItem(item) {
                window.BasketManager.get(this.basketidentifier).removeLine(item.productInfo.identifier);
                this.errors.push(this.dictionary('quickorder.errors.not_orderable').replace('{artno}', item.productInfo.artno));
            }
        },
        components: {
            SalesQuickOrderItem
        }
    }
</script>
