var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "cart-item" },
    [
      _c("div", { staticClass: "item-row" }, [
        _c("div", { staticClass: "item-product-image" }, [
          _c("img", {
            staticClass: "img-fluid",
            attrs: {
              src: _vm.item.productInfo.image_url,
              alt: _vm.item.productInfo.name,
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "item-description" }, [
          _c("h4", {
            staticClass: "m-0 pr-4",
            domProps: { innerHTML: _vm._s(_vm.item.productInfo.artno) },
          }),
          _vm._v(" "),
          _vm.item.productInfo.name
            ? _c("p", {
                staticClass: "m-0",
                domProps: { innerHTML: _vm._s(_vm.item.productInfo.name) },
              })
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "item-quantity text-center" },
          [
            _vm.createPDF
              ? [
                  _c("p", {
                    domProps: { innerHTML: _vm._s(_vm.item.quantity) },
                  }),
                ]
              : [
                  _c(
                    "add-to-basket",
                    _vm._b(
                      {
                        attrs: {
                          item: _vm.item.productInfo.identifier,
                          basketidentifier: _vm.basketidentifier,
                        },
                      },
                      "add-to-basket",
                      {
                        item: _vm.item.productInfo.identifier,
                        basketidentifier: _vm.basketidentifier,
                        orderLimit: _vm.item.productInfo.order_limit,
                        disabled: false,
                        itemOrderable: true,
                        checkOrderLimit: true,
                        minimalOrderQuantity: _vm.minimalQuantity,
                        indivisibleUnit: _vm.indivisible,
                      },
                      false
                    )
                  ),
                ],
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "item-price-unit" },
          [
            _vm.netPrice !== null
              ? [
                  _c("span", { staticClass: "mobile-header" }, [
                    _vm._v(
                      _vm._s(_vm.$dictionary("ordering.basket.price_unit"))
                    ),
                  ]),
                  _vm._v(" "),
                  _c("strong", {
                    domProps: {
                      innerHTML: _vm._s(_vm.formatPrice(_vm.netPrice)),
                    },
                  }),
                ]
              : [_c("div", { staticClass: "loader" }, [_vm._v("Loading...")])],
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "item-price-subtotal" },
          [
            _vm.subTotal !== null
              ? [
                  _c("span", { staticClass: "mobile-header" }, [
                    _vm._v(_vm._s(_vm.$dictionary("ordering.basket.subtotal"))),
                  ]),
                  _vm._v(" "),
                  _c("strong", {
                    domProps: {
                      innerHTML: _vm._s(_vm.formatPrice(_vm.subTotal)),
                    },
                  }),
                ]
              : [_c("div", { staticClass: "loader" }, [_vm._v("Loading...")])],
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "item-delete",
            attrs: { "data-html2canvas-ignore": "" },
          },
          [
            _c("i", {
              staticClass: "icon icon-trash-alt-regular",
              on: {
                click: function ($event) {
                  return _vm.removeLine()
                },
              },
            }),
          ]
        ),
      ]),
      _vm._v(" "),
      _vm._l(_vm.item.messages, function (message, key) {
        return _vm.item.messages
          ? _c("div", { key: "item-message" + _vm.item.id + "-" + key }, [
              _c(
                "div",
                { staticClass: "alert", class: "alert-" + message.level },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(message.description) +
                      "\n        "
                  ),
                ]
              ),
            ])
          : _vm._e()
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }