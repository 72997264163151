<template>
    <div class="row customer no-gutters" :class="[active ? activeClass : '']">
        <div class="radio col-2 col-lg-1">
            <input type="radio" name="customerselector" class="radio-input" :checked="active" :class="[active ? activeClass : '']" :value="customer.id" @input="onInput">
        </div>
        <div class="identifier col-5 col-lg-2">
            {{ customer.identifier }}
        </div>
        <div class="name col-5 col-lg-3" v-html="customer.name"></div>
        <div class="postal col-sm-10 offset-2 offset-lg-0 col-lg-1">
            {{ customer.postal }}
        </div>
        <div class="street col-sm-10 offset-2 offset-lg-0 col-lg">
            {{ customer.street }}
        </div>
        <div class="city col-sm-10 offset-2 offset-lg-0 col-lg-2">
            {{ customer.city }}
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                activeClass: 'active',
            }
        },
        props: {
            customer: Object,
            active: Boolean
        },
        methods: {
            onInput(value) {
                this.$emit('input', this.customer)
            }
        }
    }
</script>
